import { Stack, Text, Box } from "@chakra-ui/react";
import { ContentCard } from "../components/ContentCard";
import { Header } from "../components/Header";
import { useEffect } from "react";
import decke from "../components/images/concrete/decke.jpeg";
import fundament from "../components/images/concrete/fundament.jpeg";
import sanierung from "../components/images/concrete/sanierung.jpeg";
import sichtbeton from "../components/images/concrete/sichtbeton.jpeg";
import treppe from "../components/images/concrete/treppe.jpeg";
import { ProjectPlaner } from "../components/ProjectPlaner";

export function Concrete({ ...rest }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const services = [
    {
      title: "Beton,- und Betonsanierungsarbeiten",
      picture: sanierung,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },
    {
      title: "Betontreppen",
      picture: treppe,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },

    {
      title: "Fundamente",
      picture: fundament,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },
    {
      title: "Betondecken",
      picture: decke,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },
    {
      title: "Sichtbetonwände",
      picture: sichtbeton,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },
    ,
  ];

  return (
    <Box {...rest}>
      <Header />
      <Stack>
        <Text
          py={10}
          fontWeight={"extrabold"}
          fontSize={[20, 25, 30, 30]}
          textAlign={"center"}
        >
          Wir bieten diverse Dienstleistungen rund um Stahl- Betonarbeiten an
        </Text>
        <Stack spacing={"10vh"} pb={5}>
          {services.map((service, index) => {
            return (
              <ContentCard
                key={index}
                id={index}
                title={service.title}
                picture={service.picture}
                text={service.text}
              />
            );
          })}
        </Stack>
        <ProjectPlaner start={0} pt={5} />
        {/* <Footer pt={'10vh'} /> */}
      </Stack>
    </Box>
  );
}
