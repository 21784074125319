export const calcVW = (vw, maxW, minW) => {
  let result = window.innerWidth;
  result = ((result / 100) * vw).toFixed(0);
  if (result > maxW) {
    return maxW;
  } else {
    if (minW !== undefined) {
      if (result < minW) {
        return result;
      }
    } else {
      return result;
    }
  }
};
