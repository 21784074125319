export function shortenName(name) {
  const partsOfName = name.split(" ");

  if (partsOfName.length >= 1) {
    let shortenedName = "";
    for (let i = 0; i < partsOfName.length; i++) {
      if (i !== partsOfName.length - 1) {
        shortenedName += partsOfName[i] + " ";
      } else {
        shortenedName += partsOfName[1].charAt(0) + ".";
      }
    }
    return shortenedName;
  } else {
    // Wenn der Name nicht geteilt werden kann, gib den ursprünglichen Namen zurück
    return name;
  }
}

shortenName("Lukas Kevin Kauber");
