import { Box, Center, Stack, Text, HStack } from "@chakra-ui/react";
import { AutoTextSize } from "auto-text-size";
import { mainColor } from "../mainColor";

export function Icon(props) {
  const { icon, text, description, descriptionFontSize = 18, link } = props;
  return (
    <HStack fontWeight={"extrabold"} textAlign={"center"} spacing={10}>
      <Box
        cursor={"pointer"}
        bgColor={mainColor}
        borderRadius={0}
        w={[75, 100, 100, 100]}
        h={[75, 100, 100, 100]}
        onClick={link}
      >
        <Center w={"100%"} h={"100%"} color={"white"}>
          {icon}
        </Center>
      </Box>
      <Stack spacing={0}>
        <Text fontSize={22}>{text}</Text>
        <AutoTextSize>{description}</AutoTextSize>
      </Stack>
    </HStack>
  );
}
