import {
  Center,
  useBreakpointValue,
  HStack,
  Box,
  Stack,
  Text,
  Spacer,
  WrapItem,
  Wrap,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Header } from "../components/Header";
import axios from "axios";
import { StarIcon } from "@chakra-ui/icons";
import { mainColor } from "../components/mainColor";
import { Review } from "./Review";
import { useEffect, useState } from "react";
import { ExpandableText } from "../components/ExpandableText";
import { mainSpacing } from "../components/mainSpacing";
import { shortenName } from "../utils/shortenName";

const ReviewCard = (props) => {
  const { review, ...rest } = props;
  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });
  const d = new Date(review.date),
    dformat = [d.getDate(), d.getMonth() + 1, d.getFullYear()].join(".");

  return (
    <>
      <Box
        w={"100%"}
        minHeight={200}
        borderWidth={3}
        borderColor={mainColor}
        px={5}
        py={5}
        ml={variant === "base" && variant === "sm" && mainSpacing}
        mr={variant === "base" && variant === "sm" && mainSpacing}
        {...rest}
      >
        <Stack minHeight={200}>
          <HStack>
            <Text fontWeight={"black"} fontSize={[18, 18, 18, 20]}>
              {" "}
              {shortenName(review?.name)}{" "}
            </Text>
            <Spacer />
          </HStack>
          <ExpandableText noOfLines={4}>
            <Text fontSize={18} color="gray.600">
              {review?.review}
            </Text>
          </ExpandableText>
          <Spacer />
          <Center>
            <HStack>
              {Array(5)
                .fill("")
                .map((_, i) => (
                  <StarIcon
                    key={i}
                    color={i < review.rating ? mainColor : "gray.300"}
                  />
                ))}
            </HStack>
          </Center>
        </Stack>
      </Box>
    </>
  );
};

export function Reviews({ ...rest }) {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    axios
      .get(`https://kroll-bauausfuehrungen.de/php/getReviews.php`)
      .then((res) => {
        setReviews(res.data);
      });
  }, []);

  reviews.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  return (
    <Stack spacing={0} minH={"100vh"} {...rest}>
      <Header />
      {/* <Spacer /> */}
      <Box h={mainSpacing} />
      <Center px={mainSpacing}>
        {variant !== "lg" ? (
          <Wrap
            spacing={[mainSpacing[0], mainSpacing[1], 5, 5]}
            justify={"center"}
          >
            {reviews.map((review, index) => {
              return (
                <WrapItem w={"100%"} key={index}>
                  <Box w={"100%"}>
                    <ReviewCard review={review} key={index} />
                  </Box>
                </WrapItem>
              );
            })}
          </Wrap>
        ) : (
          <Grid
            templateColumns={`repeat(${
              reviews.length >= 5 ? 5 : reviews.length
            }, 1fr)`}
            gap={6}
          >
            {reviews.map((review, index) => {
              return (
                <GridItem w={"100%"} key={index}>
                  <ReviewCard review={review} key={index} />
                </GridItem>
              );
            })}
          </Grid>
        )}
      </Center>
      {variant !== "base" && <Box h={mainSpacing} />}
      {/* <Box pt={mainSpacing}> */}
      {variant !== "base" && (
        <Text fontWeight={"extrabold"} textAlign={"center"} fontSize={30}>
          Aus Liebe zum Handwerk
        </Text>
      )}
      {/* <Spacer /> */}
      <Box h={mainSpacing} />
      <Review /* pt={mainSpacing} */ px={mainSpacing} />
      {/* </Box> */}
      {/* <Spacer /> */}
      <Box h={mainSpacing} />
      {variant !== "base" && <Box h={30} />}
      {/* <Box pt={mainSpacing} /> */}
    </Stack>
  );
}
