import {
  Box,
  Center,
  Stack,
  HStack,
  Text,
  Image,
  Spacer,
} from "@chakra-ui/react";
import { ViewIcon, ChatIcon, TimeIcon } from "@chakra-ui/icons";
import "./RenderBlogArticle.css";

export function TabletBlogArticle(props) {
  const { children, article } = props;

  return (
    <Stack pl={3}>
      <HStack spacing={0} alignItems={"flex-start"} pt={10}>
        {/*    <Spacer /> */}
        <Center w={"100vw"}>
          <Stack /* pt={10} */>
            <Center>
              <Stack w={"70vw"}>
                <Image
                  src={article.pictureLink}
                  alt={article.pictureAlt}
                  borderRadius={10}
                />
                <Text fontSize={28} as="u" textAlign="center" fontWeight="bold">
                  {" "}
                  {article.header}{" "}
                </Text>
                <Center>
                  <HStack spacing={7} fontSize={18} pb={5}>
                    <HStack>
                      <TimeIcon />
                      <Text color="gray.500">20.04.2022</Text>
                    </HStack>
                    <HStack>
                      <ChatIcon />
                      <Text color="gray.500">
                        {article.comments.length} Kommentare{" "}
                      </Text>
                    </HStack>
                    <HStack>
                      <ViewIcon />
                      <Text color="gray.500">{article.viewCount}</Text>
                    </HStack>
                  </HStack>
                </Center>
                <Box>{children}</Box>
              </Stack>
            </Center>
          </Stack>
        </Center>
        {/*         <Box w={'28vw'}>
          <Center>
            <Stack>
              <BlogSideBar iconSize={20} iconBoxSize={39} w={'26vw'} />
            </Stack>
          </Center>
        </Box> */}
        <Spacer />
      </HStack>
    </Stack>
  );
}
