import { useBreakpointValue } from "@chakra-ui/react";
import { ImMobile } from "react-icons/im";
import { Icon } from "./Icon";

export function MobileNumberShowcase() {
  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });
  return (
    <Icon
      text={"0176 419 477 56"}
      description={"Mo.-Sa. 8:00-18:00"}
      descriptionFontSize={18.5}
      link={() => (window.location = "tel:017641947756")}
      icon={<ImMobile size={variant === "lg" ? 60 : 45} />}
    />
  );
}
