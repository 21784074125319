import { Box, Text, HStack, Center, useDisclosure, Stack, useBreakpointValue } from "@chakra-ui/react";
import { CurrentlyInProgress } from "./CurrentlyInProgress";
import { mainColor } from "./mainColor";

export function MainPageContentBlock(props) {
  const {
    children,
    title,
    image,
    link,
    side = "left",
    size = 1,
    projectPlaner = false,
    textID,
    imageID,
    ...rest
  } = props;

  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  const {
    isOpen: isOpenProgress,
    onOpen: onOpenProgress,
    onClose: onCloseProgress,
  } = useDisclosure();

  const TextBlock = (props) => {
    return (
      <Box
        w={["100vw", "35%", "30%", "35%"]}
        minH={["45px", "120px", "135px", "135px"]}
        maxH={variant === "base" && "45px"}
        bgColor={mainColor}
        ref={textID}
        cursor={"pointer"}
        {...rest}
      >
        <Center h={"100%"} w={"100%"}>
          <Text
            textAlign={"center"}
            fontWeight={"black"}
            color="white"
            fontSize={[15, 19, 20, 25, 30]}
          >
            {title}
          </Text>
        </Center>
      </Box>
    );
  };

  const ImageBlock = (props) => {
    return (
      <Box
        w={["100vw", "65%", "70%", "65%"]}
        minH={["120px", "120px", "135px", "135px"]}
        /* maxW={"900px"} */
        cursor={"pointer"}
        backgroundImage={image}
        /* backgroundRepeat={"no-repeat"} */
        backgroundSize={variant === "base" ? "120% 100%" : "100% 100%"}
        ref={imageID}
        {...rest}
      ></Box>
    );
  };
  return (
    <>
      <CurrentlyInProgress
        isOpen={isOpenProgress}
        onOpen={onOpenProgress}
        onClose={onCloseProgress}
      />
      {variant === "base" ? (
        <>
          <Stack spacing={0} onClick={() => onOpenProgress()}>
            <ImageBlock />
            <TextBlock />
          </Stack>
        </>
      ) : (
        /* <a href={link}> */
        <Center w={"100%"}>
          <HStack
            w={"100%"}
            spacing={0}
            px={[1, 1, 12, 12]}
            onClick={() => onOpenProgress()}
          >
            {side === "left" ? <TextBlock /> : <ImageBlock />}
            {side === "right" ? <TextBlock /> : <ImageBlock />}
          </HStack>
        </Center>
        /* </a> */
      )}
    </>
  );
}
