import { AiOutlineQuestionCircle, AiFillPicture } from "react-icons/ai";
import { ImPagebreak, ImCalendar } from "react-icons/im";
//import { MdHomeRepairService } from 'react-icons/md';
import { FaBuilding, FaTree } from "react-icons/fa";
import { Text, Image } from "@chakra-ui/react";
import { HiSpeakerphone } from "react-icons/hi";
import { BiBath } from "react-icons/bi";
import { FaHammer, FaRecycle, FaHome, FaRegBuilding } from "react-icons/fa";
import { RiSpeedLine } from "react-icons/ri";
import { MdKitchen, MdBalcony } from "react-icons/md";
import basement from "./images/icons/basement.webp";
import {
  Gi3DStairs,
  GiFreemasonry,
  GiFamilyHouse,
  GiConcreteBag,
  GiHomeGarage,
  GiBrickPile,
  GiChimney,
  GiBrickWall,
  GiAutoRepair,
  GiTrowel,
} from "react-icons/gi";
import { BsFillGrid3X2GapFill, BsGrid3X3 } from "react-icons/bs";
import { HStack } from "@chakra-ui/react";

// Images / Icons
import natural_stone from "./images/icons/natural-stone.png";
import paving from "./images/icons/paving.png";
import brick from "./images/icons/brick.png";
import building_block from "./images/icons/building-block.png";
import terrace from "./images/icons/terrace.png";
import foundation from "./images/icons/foundation.png";
import ladder from "./images/icons/ladder.png";
import floor from "./images/icons/floor.png";
import mosaic from "./images/icons/mosaic.png";
import modernisation from "./images/icons/modernisation.png";
import renovation from "./images/icons/renovation.png";

export const newQuestions = [
  "Aus welchem Bereich kommt Ihr Bauvorhaben?",
  "Wo sollen Arbeiten vorgenommen werden?",
  "Für welche Objekte benötigen Sie uns?",
  "Welche Tätigkeiten sollen wir übernehmen?",
  "Wie würden Sie die zu Erledigende arbeiten am ehesten beschreiben?",
  "Um welche Gebäudeart handelt es sich?",
  "In welchen Etagen sollen die Innenarbeiten durchgeführt werden?",
  "Aus welchem Material besteht das Objekt?",
  "Wie groß ist die zu bearbeitende Fläche?",
  "Wann benötigen Sie unsere hilfe?",
  "Bitte beschreiben Sie Ihr Projekt genauer",
  // Ab hier Fliesenleger
  "Wo sollen die Arbeiten durchgeführt werden?",
  "Wo genau sollen Fliesen verlegt werden?",
  "Welche Fliesenart soll verlegt werden?",
  "Welches Format soll verlegt werden?",
  "Wie groß ist die zu bearbeitende Fläche?",
  "Wie würden Sie die zu Erledigende arbeiten am ehesten beschreiben?",
  "In welchen Etagen sollen die Innenarbeiten durchgeführt werden?",
  "Wann benötigen Sie unsere hilfe?",
  "Bitte beschreiben Sie Ihr Projekt genauer.",
  //Ab Hier Stahlbetonbau
  "Wo sollen die Arbeiten durchgeführt werden?",
  "Für welche Objekte benötigen Sie uns?",
  "Aus welchem Material besteht das Objekt?",
  "Welche Tätigkeiten sollen wir übernehmen?",
  "Wie groß ist die zu bearbeitende Fläche?",
  "Wie würden Sie die zu Erledigende arbeiten am ehesten beschreiben?",
  "Um Welche Gebäudeart handelt es sich?",
  "In welchen Etagen sollen die Innenarbeiten durchgeführt werden?",
  "Wann benötigen Sie unsere hilfe?",
  "Bitte beschreiben Sie Ihr Projekt genauer.",

  // Es müssen die richtigen Verlinkungen wieder erstellt werden, für die beiden Zeitraum fragen
  "Von wann bis wann, müssen die Arbeiten erledigt sein",
  "Von wann bis wann, müssen die Arbeiten erledigt sein",
  "Wo genau sollen Fliesen verlegt werden?",
  "Wo genau sollen Fliesen verlegt werden?",
  "Für welche Objekte benötigen Sie uns?",
  "Für welche Objekte benötigen Sie uns?",
  "Für welche Objekte benötigen Sie uns?",
  "Für welche Objekte benötigen Sie uns?",
  /*   "Falls möglich wählen Sie passende Bilder aus, damit wir uns einen Überblick verschaffen können",
  "Falls möglich wählen Sie passende Bilder aus, damit wir uns einen Überblick verschaffen können", */

  // Weg für Sonstiges definieren
];

export const Anwsers = [
  [
    {
      anwser: "Maurerarbeiten",
      next: 1,
      kind: "Auftragsart",
      icon: <GiFreemasonry fontSize={70} />,
    },
    {
      anwser: "Fliesenarbeiten",
      next: 11,
      kind: "Auftragsart",
      icon: <BsFillGrid3X2GapFill fontSize={70} />,
    },
    {
      anwser: "Stahl- Betonbau",
      next: 20,
      kind: "Auftragsart",
      icon: <GiConcreteBag fontSize={70} />,
    },
    {
      anwser: "Sonstiges",
      next: 25,
      kind: "Auftragsart",
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  [
    {
      anwser: "Außerhalb des Hauses",
      next: 35,
      prev: 0,
      icon: <FaTree fontSize={70} />,
    },
    {
      anwser: "Innerhalb des Hauses",
      next: 36,
      prev: 0,
      icon: <FaHome fontSize={70} />,
    },
    {
      anwser: "Beides",
      next: 2,
      prev: 0,
      icon: (
        <HStack spacing={0}>
          <FaHome fontSize={70} />
          <FaTree fontSize={70} />
        </HStack>
      ),
    },
  ],
  //Mehrfachauswahl ermöglichen
  // Textblock einbauen
  //Für welche Objekte benötigen Sie uns?
  [
    {
      anwser: "Innenwände",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <AiFillPicture fontSize={70} />,
    },
    {
      anwser: "Außenfassade",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <FaHome fontSize={70} />,
    },
    {
      anwser: "Balkon",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <MdBalcony fontSize={70} />,
    },
    {
      anwser: "Terasse",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <Image w={75} src={terrace} />,
    },
    {
      anwser: "Fundament",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <Image w={70} src={foundation} />,
    },
    {
      anwser: "Keller",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <Image w={75} src={basement} />,
    },
    {
      anwser: "Garage",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <GiHomeGarage fontSize={70} />,
    },
    {
      anwser: "Treppe",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <Gi3DStairs fontSize={70} />,
    },
    {
      anwser: "Schornstein",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <GiChimney fontSize={70} />,
    },
    {
      anwser: "Gartenmauer",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <GiBrickWall fontSize={70} />,
    },
    {
      anwser: "Eingangspodest",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <Image w={70} src={ladder} />,
    },
    {
      anwser: "Sonstiges",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //Welche Tätigkeiten sollen wir übernehmen?
  [
    {
      anwser: "Bau",
      next: 4,
      prev: 2,
      multiple: true,
      icon: <FaHammer fontSize={70} />,
    },
    {
      anwser: "Sanierung",
      next: 4,
      prev: 2,
      multiple: true,
      icon: <GiAutoRepair fontSize={70} />,
    },
    {
      anwser: "Durchbruch",
      next: 4,
      prev: 2,
      multiple: true,
      icon: <ImPagebreak fontSize={70} />,
    },
    {
      anwser: "Estrichlegen",
      next: 4,
      prev: 2,
      multiple: true,
      icon: <BsFillGrid3X2GapFill fontSize={70} />, // Icon fehlt
    },
    {
      anwser: "Verputzen",
      next: 4,
      prev: 2,
      multiple: true,
      icon: <GiTrowel fontSize={70} />,
    },
    {
      anwser: "Verklinkern",
      next: 4,
      prev: 2,
      multiple: true,
      icon: <GiBrickPile fontSize={70} />,
    },
    {
      anwser: "Verfugen",
      next: 4,
      prev: 2,
      multiple: true,
      icon: <GiBrickPile fontSize={70} />, // Icon fehlt
    },
    {
      anwser: "Abdichten",
      next: 4,
      prev: 2,
      multiple: true,
      icon: <GiBrickPile fontSize={70} />, // Icon fehlt
    },
    {
      anwser: "Pflastern",
      next: 4,
      prev: 2,
      multiple: true,
      icon: <Image src={paving} w={70} />,
    },
    {
      anwser: "Sonstiges",
      next: 4,
      prev: 2,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //Wie würden Sie die zu erledigende Reparatur/Sanierung am ehesten beschreiben?
  [
    {
      anwser: "Instandsetzung",
      next: 5,
      prev: 3,
      icon: <GiAutoRepair fontSize={50} />,
    },
    {
      anwser: "Modernisierung",
      next: 5,
      prev: 3,
      icon: <Image w={70} src={modernisation} />,
    },
    {
      anwser: "Kernsanierung",
      next: 5,
      prev: 3,
      icon: <Image w={70} src={renovation} />,
    },
    {
      anwser: "Neubau",
      next: 5,
      prev: 3,
      icon: <FaHammer fontSize={70} />,
    },
    {
      anwser: "Sonstiges",
      next: 5,
      prev: 3,
      icon: <AiOutlineQuestionCircle fontSize={70} />, //
    },
  ],
  //Um welche Gebäudeart handelt es sich?
  [
    {
      anwser: "Haus (Neubau)",
      next: 6,
      prev: 4,
      icon: <FaHome fontSize={70} />,
    },
    {
      anwser: "Haus (Altbau)",
      next: 6,
      prev: 4,
      icon: <FaHome fontSize={70} />,
    },
    {
      anwser: "Wohnung (Neubau)",
      next: 6,
      prev: 4,
      icon: <FaRegBuilding fontSize={70} />,
    },
    {
      anwser: "Wohnung (Altbau)",
      next: 6,
      prev: 4,
      icon: <FaRegBuilding fontSize={70} />,
    },
    {
      anwser: "Sonstige Gebäudeart",
      next: 6,
      prev: 4,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
      // Wenn das ausgewählt wird, dann Textfeld anzeigen
    },
  ],
  //In welchen Etagen sollen die Innenarbeiten durchgeführt werden?
  [
    {
      anwser: "Keller",
      next: 7,
      prev: 5,
      multiple: true,
      icon: <Image w={70} src={basement} />,
    },
    {
      anwser: "Erdgeschoss",
      next: 7,
      prev: 5,
      multiple: true,
      icon: <FaBuilding fontSize={70} />,
    },
    {
      anwser: "1. Etage",
      next: 7,
      prev: 5,
      multiple: true,
      icon: <Text fontSize={45}>1.</Text>,
    },
    {
      anwser: "2. Etage",
      next: 7,
      prev: 5,
      multiple: true,
      icon: <Text fontSize={45}>2.</Text>,
    },
    {
      anwser: "3. Etage",
      next: 7,
      prev: 5,
      multiple: true,
      icon: <Text fontSize={45}>3.</Text>,
    },
    {
      anwser: "4. Etage",
      next: 7,
      prev: 5,
      multiple: true,
      icon: <Text fontSize={45}>4.</Text>,
    },
    {
      anwser: "5. Etage oder höher",
      next: 7,
      prev: 5,
      multiple: true,
      icon: <Text fontSize={45}>{"> 5"}</Text>,
    },
  ],
  //Aus welchem Material besteht das Objekt?
  [
    {
      anwser: "Beton",
      next: 8,
      prev: 6,
      multiple: true,
      icon: <GiConcreteBag fontSize={70} />,
    },
    {
      anwser: "Klinker / Ziegel",
      next: 8,
      prev: 6,
      multiple: true,
      icon: <GiBrickPile fontSize={70} />,
    },
    {
      anwser: "Kalksandstein",
      next: 8,
      prev: 6,
      multiple: true,
      icon: <Image w={70} src={building_block} />,
    },
    {
      anwser: "Porenbeton",
      next: 8,
      prev: 6,
      multiple: true,
      icon: <Image w={70} src={brick} />,
    },
    {
      anwser: "Naturstein",
      next: 8,
      prev: 6,
      multiple: true,
      icon: <Image src={natural_stone} w={70} />,
    },
    {
      anwser: "Fachwerk",
      next: 8,
      prev: 6,
      multiple: true,
      icon: <GiFamilyHouse fontSize={70} />,
    },
    {
      anwser: "Sonstiges",
      next: 8,
      prev: 6,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //Wie groß ist die zu bearbeitende Fläche ungefähr?
  [
    {
      anwser: "Ich bin mir nicht sicher",
      next: 9,
      prev: 7,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
    {
      anwser: "5m² - 20m²",
      next: 9,
      prev: 7,
      icon: <Text fontSize={25}> {"5m² - 20m²"}</Text>,
    },
    {
      anwser: "20m² - 50m²",
      next: 9,
      prev: 7,
      icon: <Text fontSize={25}> {"20m² - 50m²"}</Text>,
    },
    {
      anwser: "51m² bis 100m²",
      next: 9,
      prev: 7,
      icon: <Text fontSize={25}> {"51m²-100m²"}</Text>,
    },
    {
      anwser: "101m² bis 150m²",
      next: 9,
      prev: 7,
      icon: <Text fontSize={25}> {"101m²-150m²"}</Text>,
    },
    {
      anwser: "151m² bis 200m²",
      next: 9,
      prev: 7,
      icon: <Text fontSize={25}> {"151m²-200m²"}</Text>,
    },
    {
      anwser: "201m² bis 250m²",
      next: 9,
      prev: 7,
      icon: <Text fontSize={25}> {"201m²-250m²"}</Text>,
    },
    {
      anwser: "Mehr als 251m²",
      next: 9,
      prev: 7,
      icon: <Text fontSize={25}> {"> 250m²"}</Text>,
    },
  ],
  //Wann benötigen Sie unsere hilfe?
  [
    {
      anwser: "Ich bin zeitlich flexibel",
      next: 10,
      prev: 8,
      icon: <FaRecycle fontSize={70} />,
    },
    {
      anwser: "bestimmter Zeitraum",
      next: 30,
      prev: 8,
      icon: <ImCalendar fontSize={70} />,
      //Erstes und letztes Datum auswählen lassen
    },
    {
      anwser: "So schnell wie möglich",
      next: 10,
      prev: 8,
      icon: <RiSpeedLine fontSize={70} />,
    },
    {
      anwser: "nur Beratung gewünscht",
      next: 10,
      prev: 8,
      icon: <HiSpeakerphone fontSize={70} />,
    },
  ],
  //Bitte beschreiben Sie Ihr Projekt genauer.
  [
    {
      anwser: "Textblock",
      /*   next: 24, */
      next: "finished",
      text: true,
      placeholder:
        "Bitte beschreiben Sie Ihr Projekt so genau wie möglich. Geben Sie wenn möglich die Maße der betreffenden Objekte an und bechreiben Sie, was genau getan werden soll.",
      prev: 9,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //Wo sollen die Arbeiten durchgeführt werden?
  [
    {
      anwser: "Außerhalb des Hauses",
      next: 34,
      prev: 0,
      icon: <FaTree fontSize={70} />,
    },
    {
      anwser: "Innerhalb des Hauses",
      next: 33,
      prev: 0,
      icon: <FaHome fontSize={70} />,
    },
    {
      anwser: "Beides",
      next: 12,
      prev: 0,
      icon: (
        <HStack spacing={0}>
          <FaHome fontSize={70} />
          <FaTree fontSize={70} />
        </HStack>
      ),
    },
  ],
  //in Welchen Räumen
  [
    {
      anwser: "Badezimmer",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <BiBath fontSize={70} />,
    },
    {
      anwser: "Küche",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <MdKitchen fontSize={70} />,
    },
    {
      anwser: "Fußboden",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <Image w={70} src={floor} />,
    },
    {
      anwser: "Treppe",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <Gi3DStairs fontSize={70} />,
    },
    {
      anwser: "Eingangspodest",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <Image w={70} src={ladder} />,
    },
    {
      anwser: "Terrasse",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <Image w={75} src={terrace} />,
    },
    {
      anwser: "Sonstiges",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //Welche Fliesenart soll verlegt werden?
  [
    {
      anwser: "Keramikfliesen",
      next: 14,
      prev: 12,
      multiple: true,
      icon: <BsFillGrid3X2GapFill fontSize={70} />,
    },
    {
      next: 14,
      anwser: "Natursteinplatte",
      prev: 12,
      multiple: true,
      icon: <Image src={natural_stone} w={70} />,
    },
    {
      anwser: "Ziegelfliese",
      next: 14,
      prev: 12,
      multiple: true,
      icon: <BsGrid3X3 fontSize={70} />,
    },
    {
      anwser: "Sonstige",
      next: 14,
      prev: 12,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  // Welches Format soll verlegt werden?
  [
    {
      anwser: "Mosaik",
      multiple: true,
      tile: true,
      next: 15,
      prev: 13,
      icon: <Image w={50} src={mosaic} />,
    },
    {
      anwser: "20x40cm",
      multiple: true,
      tile: true,
      next: 15,
      prev: 13,
      icon: <Text fontSize={25}> {"20x40cm"}</Text>,
    },
    {
      anwser: "30x60cm",
      multiple: true,
      tile: true,
      next: 15,
      prev: 13,
      icon: <Text fontSize={25}> {"30x60cm"}</Text>,
    },
    {
      anwser: "60x90cm",
      multiple: true,
      tile: true,
      next: 15,
      prev: 13,
      icon: <Text fontSize={25}> {"60x90cm"}</Text>,
    },
    {
      anwser: "45x90cm",
      multiple: true,
      tile: true,
      next: 15,
      prev: 13,
      icon: <Text fontSize={25}> {"45x90cm"}</Text>,
    },
    /*     {
        anwser: "15x120cm",
        multiple: true,
        tile: true,
        next: 15,
        prev: 13,
        icon: <Text fontSize={25}> {"15x120cm"}</Text>,
      }, */
    {
      anwser: "120x60cm",
      multiple: true,
      tile: true,
      next: 15,
      prev: 13,
      icon: <Text fontSize={25}> {"120x60cm"}</Text>,
    },
    {
      anwser: "120x120cm",
      multiple: true,
      tile: true,
      next: 15,
      prev: 13,
      icon: <Text fontSize={25}> {"120x120cm"}</Text>,
    },
    /*     {
        anwser: "120x240cm",
        multiple: true,
        tile: true,
        next: 15,
        prev: 13,
        icon: <Text fontSize={25}> {"120x240cm"}</Text>,
      }, */
    {
      anwser: "Sonstige",
      multiple: true,
      tile: true,
      next: 15,
      prev: 13,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  [
    //Wie groß ist die zu bearbeitende Fläche?
    {
      anwser: "5m² - 20m²",
      next: 16,
      prev: 14,
      icon: <Text fontSize={25}> {"5m² - 20m²"}</Text>,
    },
    {
      anwser: "20m² - 50m²",
      next: 16,
      prev: 14,
      icon: <Text fontSize={25}> {"20m² - 50m²"}</Text>,
    },
    {
      anwser: "51m² bis 100m²",
      next: 16,
      prev: 14,
      icon: <Text fontSize={25}> {"51m²-100m²"}</Text>,
    },
    {
      anwser: "101m² bis 150m²",
      next: 16,
      prev: 14,
      icon: <Text fontSize={25}> {"101m²-150m²"}</Text>,
    },
    {
      anwser: "151m² bis 200m²",
      next: 16,
      prev: 14,
      icon: <Text fontSize={25}> {"151m²-200m²"}</Text>,
    },
    {
      anwser: "mehr als 201m²",
      next: 16,
      prev: 14,
      icon: <Text fontSize={25}> {"mehr als 201m²"}</Text>,
    },
  ],
  //Wie würden Sie die zu erledigende Reparatur/Sanierung am ehesten beschreiben?
  [
    {
      anwser: "Instandsetzung",
      next: 17,
      prev: 15,
      icon: <GiAutoRepair fontSize={50} />, // Icon fehlt
    },
    {
      anwser: "Modernisierung",
      next: 17,
      prev: 15,
      icon: <Image w={70} src={modernisation} />,
    },
    {
      anwser: "Kernsanierung",
      next: 17,
      prev: 15,
      icon: <Image w={70} src={renovation} />,
    },
    {
      anwser: "Neubau",
      next: 17,
      prev: 15,
      icon: <FaHammer fontSize={70} />,
    },
    {
      anwser: "Sonstiges",
      next: 17,
      prev: 15,
      icon: <AiOutlineQuestionCircle fontSize={70} />, //
    },
  ],
  //In welchen Etagen sollen die Innenarbeiten durchgeführt werden?
  [
    {
      anwser: "Keller",
      next: 18,
      prev: 16,
      multiple: true,
      icon: <Image w={70} src={basement} />,
    },
    {
      anwser: "Erdgeschoss",
      next: 18,
      prev: 16,
      multiple: true,
      icon: <FaBuilding fontSize={70} />,
    },
    {
      anwser: "1. Etage",
      next: 18,
      prev: 16,
      multiple: true,
      icon: <Text fontSize={45}>1.</Text>,
    },
    {
      anwser: "2. Etage",
      next: 18,
      prev: 16,
      multiple: true,
      icon: <Text fontSize={45}>2.</Text>,
    },
    {
      anwser: "3. Etage",
      next: 18,
      prev: 16,
      multiple: true,
      icon: <Text fontSize={45}>3.</Text>,
    },
    {
      anwser: "4. Etage",
      next: 18,
      prev: 16,
      multiple: true,
      icon: <Text fontSize={45}>4.</Text>,
    },
    {
      anwser: "5. Etage oder höher",
      next: 18,
      prev: 16,
      multiple: true,
      icon: <Text fontSize={45}>{"> 5"}</Text>,
    },
  ],
  //Wann benötigen Sie unsere hilfe?
  [
    {
      anwser: "Ich bin zeitlich flexibel",
      next: 19,
      prev: 17,
      icon: <FaRecycle fontSize={70} />,
    },
    {
      anwser: "bestimmter Zeitraum",
      next: 31,
      prev: 17,
      icon: <ImCalendar fontSize={70} />,
      //Erstes und letztes Datum auswählen lassen
    },
    {
      anwser: "So schnell wie möglich",
      next: 19,
      prev: 17,
      icon: <RiSpeedLine fontSize={70} />,
    },
    {
      anwser: "nur Beratung gewünscht",
      next: 19,
      prev: 17,
      icon: <HiSpeakerphone fontSize={70} />,
    },
  ],
  //Bitte beschreiben Sie Ihr Projekt genauer.
  [
    {
      anwser: "Textblock",
      text: true,
      placeholder:
        "Bitte beschreiben Sie Ihr Projekt so genau wie möglich. Geben Sie wenn möglich die Maße der betreffenden Objekte an und bechreiben Sie, was genau getan werden soll.",
      /*     next: 25, */
      next: "finished",
      prev: 18,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //Wo sollen die Arbeiten durchgeführt werden?
  [
    {
      anwser: "Außerhalb des Hauses",
      next: 37,
      prev: 0,
      icon: <FaTree fontSize={70} />,
    },
    {
      anwser: "Innerhalb des Hauses",
      next: 38,
      prev: 0,
      icon: <FaHome fontSize={70} />,
    },
    {
      anwser: "Beides",
      next: 21,
      prev: 0,
      icon: (
        <HStack spacing={0}>
          <FaHome fontSize={70} />
          <FaTree fontSize={70} />
        </HStack>
      ),
    },
  ],
  //Für welche Objekte benötigen Sie uns?
  [
    {
      anwser: "Innenwände",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <AiFillPicture fontSize={70} />,
    },
    {
      anwser: "Außenfassade",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <FaHome fontSize={70} />,
    },
    {
      anwser: "Balkon",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <MdBalcony fontSize={70} />,
    },
    {
      anwser: "Terasse",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <Image w={75} src={terrace} />,
    },
    {
      anwser: "Fundament",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <Image w={70} src={foundation} />,
    },
    {
      anwser: "Keller",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <Image w={75} src={basement} />,
    },
    {
      anwser: "Garage",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <GiHomeGarage fontSize={70} />,
    },
    {
      anwser: "Treppe",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <Gi3DStairs fontSize={70} />,
    },
    {
      anwser: "Schornstein",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <GiChimney fontSize={70} />,
    },
    {
      anwser: "Gartenmauer",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <GiBrickWall fontSize={70} />,
    },
    {
      anwser: "Eingangspodest",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <Image w={70} src={ladder} />,
    },
    {
      anwser: "Sonstiges",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //Aus welchem Material besteht das Objekt?
  [
    {
      anwser: "Beton",
      next: 23,
      prev: 21,
      multiple: true,
      icon: <GiConcreteBag fontSize={70} />,
    },
    {
      anwser: "Klinker / Ziegel",
      next: 23,
      prev: 21,
      multiple: true,
      icon: <GiBrickPile fontSize={70} />,
    },
    {
      anwser: "Kalksandstein",
      next: 23,
      prev: 21,
      multiple: true,
      icon: <Image w={70} src={building_block} />,
    },
    {
      anwser: "Porenbeton",
      next: 23,
      prev: 21,
      multiple: true,
      icon: <Image w={70} src={brick} />,
    },
    {
      anwser: "Naturstein",
      next: 23,
      prev: 21,
      multiple: true,
      icon: <Image src={natural_stone} w={70} />,
    },
    {
      anwser: "Fachwerk",
      next: 23,
      prev: 21,
      multiple: true,
      icon: <GiFamilyHouse fontSize={70} />,
    },
    {
      anwser: "Sonstiges",
      next: 23,
      prev: 21,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //Welche Tätigkeiten sollen wir übernehmen?
  [
    {
      anwser: "Bau",
      next: 24,
      prev: 22,
      multiple: true,
      icon: <FaHammer fontSize={70} />,
    },
    {
      anwser: "Abbruch",
      next: 24,
      prev: 22,
      multiple: true,
      icon: <GiAutoRepair fontSize={70} />, // Icon fehlt
    },
    {
      anwser: "Abdichtung",
      next: 24,
      prev: 22,
      multiple: true,
      icon: <GiBrickPile fontSize={70} />, // Icon fehlt
    },
    {
      anwser: "Sonstiges",
      next: 24,
      prev: 22,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //Wie groß ist die zu bearbeitende Fläche ungefähr?
  [
    {
      anwser: "Ich bin mir nicht sicher",
      next: 25,
      prev: 23,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
    {
      anwser: "5m² - 20m²",
      next: 25,
      prev: 23,
      icon: <Text fontSize={25}> {"5m² - 20m²"}</Text>,
    },
    {
      anwser: "20m² - 50m²",
      next: 25,
      prev: 23,
      icon: <Text fontSize={25}> {"20m² - 50m²"}</Text>,
    },
    {
      anwser: "51m² bis 100m²",
      next: 25,
      prev: 23,
      icon: <Text fontSize={25}> {"51m²-100m²"}</Text>,
    },
    {
      anwser: "101m² bis 150m²",
      next: 25,
      prev: 23,
      icon: <Text fontSize={25}> {"101m²-150m²"}</Text>,
    },
    {
      anwser: "151m² bis 200m²",
      next: 25,
      prev: 23,
      icon: <Text fontSize={25}> {"151m²-200m²"}</Text>,
    },
    {
      anwser: "201m² bis 250m²",
      next: 25,
      prev: 23,
      icon: <Text fontSize={25}> {"201m²-250m²"}</Text>,
    },
    {
      anwser: "Mehr als 251m²",
      next: 25,
      prev: 23,
      icon: <Text fontSize={25}> {"> 250m²"}</Text>,
    },
  ],
  //Wie würden Sie die zu erledigende Reparatur/Sanierung am ehesten beschreiben?
  [
    {
      anwser: "Instandsetzung",
      next: 26,
      prev: 24,
      icon: <GiAutoRepair fontSize={50} />,
    },
    {
      anwser: "Modernisierung",
      next: 26,
      prev: 24,
      icon: <Image w={70} src={modernisation} />,
    },
    {
      anwser: "Kernsanierung",
      next: 26,
      prev: 24,
      icon: <Image w={70} src={renovation} />,
    },
    {
      anwser: "Neubau",
      next: 26,
      prev: 24,
      icon: <FaHammer fontSize={70} />,
    },
    {
      anwser: "Sonstiges",
      next: 26,
      prev: 24,
      icon: <AiOutlineQuestionCircle fontSize={70} />, //
    },
  ],
  //Um welche Gebäudeart handelt es sich?
  [
    {
      anwser: "Haus (Neubau)",
      next: 27,
      prev: 25,
      icon: <FaHome fontSize={70} />,
    },
    {
      anwser: "Haus (Altbau)",
      next: 27,
      prev: 25,
      icon: <FaHome fontSize={70} />,
    },
    {
      anwser: "Wohnung (Neubau)",
      next: 27,
      prev: 25,
      icon: <FaRegBuilding fontSize={70} />,
    },
    {
      anwser: "Wohnung (Altbau)",
      next: 27,
      prev: 25,
      icon: <FaRegBuilding fontSize={70} />,
    },
    {
      anwser: "Sonstige Gebäudeart",
      next: 27,
      prev: 25,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
      // Wenn das ausgewählt wird, dann Textfeld anzeigen
    },
  ],
  //In welchen Etagen sollen die Innenarbeiten durchgeführt werden?
  [
    {
      anwser: "Keller",
      next: 28,
      prev: 26,
      multiple: true,
      icon: <Image w={70} src={basement} />,
    },
    {
      anwser: "Erdgeschoss",
      next: 28,
      prev: 26,
      multiple: true,
      icon: <FaBuilding fontSize={70} />,
    },
    {
      anwser: "1. Etage",
      next: 28,
      prev: 26,
      multiple: true,
      icon: <Text fontSize={45}>1.</Text>,
    },
    {
      anwser: "2. Etage",
      next: 28,
      prev: 26,
      multiple: true,
      icon: <Text fontSize={45}>2.</Text>,
    },
    {
      anwser: "3. Etage",
      next: 28,
      prev: 26,
      multiple: true,
      icon: <Text fontSize={45}>3.</Text>,
    },
    {
      anwser: "4. Etage",
      next: 28,
      prev: 26,
      multiple: true,
      icon: <Text fontSize={45}>4.</Text>,
    },
    {
      anwser: "5. Etage oder höher",
      next: 28,
      prev: 26,
      multiple: true,
      icon: <Text fontSize={45}>{"> 5"}</Text>,
    },
  ],
  //Wann benötigen Sie unsere hilfe?
  [
    {
      anwser: "Ich bin zeitlich flexibel",
      next: 29,
      prev: 27,
      icon: <FaRecycle fontSize={70} />,
    },
    {
      anwser: "bestimmter Zeitraum",
      next: 32,
      prev: 27,
      icon: <ImCalendar fontSize={70} />,
      //Erstes und letztes Datum auswählen lassen
    },
    {
      anwser: "So schnell wie möglich",
      next: 29,
      prev: 27,
      icon: <RiSpeedLine fontSize={70} />,
    },
    {
      anwser: "nur Beratung gewünscht",
      next: 29,
      prev: 27,
      icon: <HiSpeakerphone fontSize={70} />,
    },
  ],
  //Bitte beschreiben Sie Ihr Projekt genauer.
  [
    {
      anwser: "Textblock",
      /*   next: 24, */
      next: "finished",
      text: true,
      placeholder:
        "Bitte beschreiben Sie Ihr Projekt so genau wie möglich. Geben Sie wenn möglich die Maße der betreffenden Objekte an und bechreiben Sie, was genau getan werden soll.",
      prev: 28,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],

  //30
  //In welchem Zeitraum sollen die Arbeiten durchgeführt werden?
  [
    {
      anwser: "Datumseingabe",
      next: 10,
      prev: 9,
      date: true,
      icon: <FaHome fontSize={70} />,
    },
  ],
  //31
  //In welchem Zeitraum sollen die Arbeiten durchgeführt werden?
  [
    {
      anwser: "Datumseingabe",
      next: 19,
      prev: 18,
      date: true,
      icon: <FaHome fontSize={70} />,
    },
  ],
  //32
  //In welchem Zeitraum sollen die Arbeiten durchgeführt werden?
  [
    {
      anwser: "Datumseingabe",
      next: 29,
      prev: 28,
      date: true,
      icon: <FaHome fontSize={70} />,
    },
  ],
  //33 in Welchen Räumen (vorher Indoor ausgewählt)
  [
    {
      anwser: "Badezimmer",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <BiBath fontSize={70} />,
    },
    {
      anwser: "Küche",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <MdKitchen fontSize={70} />,
    },
    {
      anwser: "Fußboden",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <Image w={70} src={floor} />,
    },
    {
      anwser: "Treppe",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <Gi3DStairs fontSize={70} />,
    },
    {
      anwser: "Sonstiges",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //34 in Welchen Räumen (vorher Outdoor ausgewählt)
  [
    {
      anwser: "Fußboden",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <Image w={70} src={floor} />,
    },
    {
      anwser: "Treppe",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <Gi3DStairs fontSize={70} />,
    },
    {
      anwser: "Eingangspodest",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <Image w={70} src={ladder} />,
    },
    {
      anwser: "Terrasse",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <Image w={75} src={terrace} />,
    },
    {
      anwser: "Sonstiges",
      next: 13,
      prev: 11,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //35 Für welche Objekte benötigen Sie uns? (vorher Outdoor)
  [
    {
      anwser: "Außenfassade",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <FaHome fontSize={70} />,
    },
    {
      anwser: "Balkon",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <MdBalcony fontSize={70} />,
    },
    {
      anwser: "Terasse",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <Image w={75} src={terrace} />,
    },
    {
      anwser: "Fundament",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <Image w={70} src={foundation} />,
    },
    {
      anwser: "Garage",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <GiHomeGarage fontSize={70} />,
    },
    {
      anwser: "Treppe",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <Gi3DStairs fontSize={70} />,
    },
    {
      anwser: "Schornstein",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <GiChimney fontSize={70} />,
    },
    {
      anwser: "Gartenmauer",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <GiBrickWall fontSize={70} />,
    },
    {
      anwser: "Eingangspodest",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <Image w={70} src={ladder} />,
    },
    {
      anwser: "Sonstiges",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //36 Für welche Objekte benötigen Sie uns? (vorher Indoor)
  [
    {
      anwser: "Innenwände",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <AiFillPicture fontSize={70} />,
    },
    {
      anwser: "Fundament",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <Image w={70} src={foundation} />,
    },
    {
      anwser: "Keller",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <Image w={75} src={basement} />,
    },
    {
      anwser: "Garage",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <GiHomeGarage fontSize={70} />,
    },
    {
      anwser: "Treppe",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <Gi3DStairs fontSize={70} />,
    },
    {
      anwser: "Sonstiges",
      next: 3,
      prev: 1,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //37 Für welche Objekte benötigen Sie uns? (Vorher Outdoor)
  [
    {
      anwser: "Balkon",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <MdBalcony fontSize={70} />,
    },
    {
      anwser: "Terasse",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <Image w={75} src={terrace} />,
    },
    {
      anwser: "Fundament",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <Image w={70} src={foundation} />,
    },
    {
      anwser: "Garage",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <GiHomeGarage fontSize={70} />,
    },
    {
      anwser: "Treppe",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <Gi3DStairs fontSize={70} />,
    },
    {
      anwser: "Schornstein",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <GiChimney fontSize={70} />,
    },
    {
      anwser: "Gartenmauer",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <GiBrickWall fontSize={70} />,
    },
    {
      anwser: "Eingangspodest",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <Image w={70} src={ladder} />,
    },
    {
      anwser: "Sonstiges",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
  //38 Für welche Objekte benötigen Sie uns? (Vorher Indoor)
  [
    {
      anwser: "Innenwände",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <AiFillPicture fontSize={70} />,
    },

    {
      anwser: "Fundament",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <Image w={70} src={foundation} />,
    },
    {
      anwser: "Keller",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <Image w={75} src={basement} />,
    },
    {
      anwser: "Garage",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <GiHomeGarage fontSize={70} />,
    },
    {
      anwser: "Treppe",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <Gi3DStairs fontSize={70} />,
    },
    {
      anwser: "Schornstein",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <GiChimney fontSize={70} />,
    },
    {
      anwser: "Gartenmauer",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <GiBrickWall fontSize={70} />,
    },
    {
      anwser: "Sonstiges",
      next: 22,
      prev: 20,
      multiple: true,
      icon: <AiOutlineQuestionCircle fontSize={70} />,
    },
  ],
];
