import { Header } from "../components/Header";
import { ProjectPlaner } from "../components/ProjectPlaner";
import { Stack, Spacer } from "@chakra-ui/react";

export function ProjectPlanerPage() {
  return (
    <Stack minH={"100vh"}>
      <Header />
      <Spacer />
      <ProjectPlaner start={0} pt={5} />
      <Spacer />
    </Stack>
  );
}
