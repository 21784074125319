export function convertToHtml(json, name, tel, email) {
  let newList = [];
  json = JSON.parse(json);
  for (let i = 0; i < json.length; i++) {
    if (JSON.parse(json[i])?.anwser[0]?.content === undefined) {
      newList.push(JSON.parse(json[i]));
    }
  }

  let htmlText = "";
  for (let n = 0; n < newList.length; n++) {
    htmlText += newList[n].question + "<br/>";
    if (Array.isArray(newList[n].anwser)) {
      for (let k = 0; k < newList[n].anwser.length; k++) {
        let test = newList[n].anwser[k];
        htmlText += test;
      }
    } else {
      htmlText += `${newList[n].anwser}`;
    }
    htmlText += "<br/> <br/>";
  }
  htmlText += `Telefonnummer : ${tel}`;
  htmlText += `<br/> Name : ${name}`;
  return htmlText;
}
