import { Image, useBreakpointValue } from "@chakra-ui/react";
import { Icon } from "./Icon";
import telephone from "../../components/images/icons/telefon.png";
import { AiFillPhone } from "react-icons/ai";

export function TelephoneNumberShowcase() {
  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });
  return (
    <Icon
      text={"04343 439 047 0"}
      description={"Mo.-Sa. 8:00-18:00"}
      descriptionFontSize={20}
      icon={<Image src={telephone} w={variant === "lg" ? 50 : 45} />}
      link={() => (window.location = "tel:043434390470")}
    />
  );
}
