import { Heading, Stack, Text, Box, ListItem, UnorderedList } from "@chakra-ui/react";
import { useEffect } from "react";
import { Header } from "../components/Header";
import { mainColor } from "../components/mainColor";
import { mainSpacing } from "../components/mainSpacing";

export function Datasecruity({ ...rest }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const Headline = (props) => {
    const { title, width, ...rest } = props;
    
    return (
      <>
        <Box pt={5}>
          <Heading
            color="black"
            style={{ color: mainColor, textDecoration: "underline" }}
            {...rest}
          >
            <Box color="black">{title}</Box>
          </Heading>
          {/*           <Box
            h={1}
            w={variant !== "base" && width}
            maxW={variant !== "base" && "70vw"}
            pr={variant === "base" && 10}
            bgColor={mainColor}
          >
            {" "}
          </Box> */}
        </Box>
      </>
    );
  };

  return (
    <Box {...rest}>
      <Header />
      <Stack
        pt={5}
        pb={"70px"}
        px={mainSpacing}
        spacing={[2, 5, 5, 5]}
        fontSize={18}
      >
        <Headline
          title="Datenschutzerklärung "
          as={"h1"}
          fontSize={[28, 38, 42, 42]}
          width={430}
        />
        <Headline
          title="Einleitung und allgemeine Angaben"
          as={"h2"}
          fontSize={25}
          width={410}
        />
        <Text>
          Vielen Dank für Ihr Interesse an unserer Website. Der Schutz Ihrer
          personenbezogenen Daten ist uns ein sehr wichtiges Anliegen. Im
          Folgenden finden Sie Informationen zum Umgang mit Ihren Daten, die
          durch Ihre Nutzung unserer Website erfasst werden. Die Verarbeitung
          Ihrer Daten erfolgt entsprechend den gesetzlichen Regelungen zum
          Datenschutz.
        </Text>
        <Headline
          title="Verantwortliche Stelle im Sinne des Datenschutzrechts"
          as={"h2"}
          fontSize={25}
          width={640}
        />
        <Text>
          Kroll GmbH Bauausführungen
          <br />
          Julius-Brecht-Str. 24
          <br />
          24148 Kiel
          <br />
          kontakt@kroll-bau.com
          <br />
          0176 419 477 56
        </Text>
        <Headline
          title="Begriffsbestimmungen"
          as={"h2"}
          fontSize={25}
          width={270}
        />
        <Text>
          Unsere Datenschutzerklärung soll für jedermann einfach und
          verständlich sein. In dieser Datenschutzerklärung werden in der Regel
          die offiziellen Begriffe der Datenschutzgrundverordnung (DSGVO)
          verwendet. Die offiziellen Begriffsbestimmungen werden in Art. 4 DSGVO
          erläutert.
        </Text>
        <Headline
          title="Datenverarbeitung durch den Besuch unserer Website"
          as={"h2"}
          fontSize={25}
          width={630}
        />
        <Text>
          Wenn Sie unsere Webseiten aufrufen, ist es technisch notwendig, dass
          über Ihren Internetbrowser Daten an unseren Webserver übermittelt
          werden. Folgende Daten werden während einer laufenden Verbindung zur
          Kommunikation zwischen Ihrem Internetbrowser und unserem Webserver
          aufgezeichnet:
        </Text>
        <UnorderedList pl={[5, 8, 15, 20]}>
          <ListItem>Datum und Uhrzeit der Anforderung</ListItem>
          <ListItem>Name der angeforderten Datei</ListItem>
          <ListItem>
            Seite, von der aus die Datei angefordert wurde Zugriffsstatus
          </ListItem>
          <ListItem>
            Verwendeter Webbrowser und verwendetes Betriebssystem
          </ListItem>
          <ListItem>
            (Vollständige) IP-Adresse des anfordernden Rechners
          </ListItem>
          <ListItem>Übertragene Datenmenge</ListItem>
        </UnorderedList>
        <Text>
          Die aufgelisteten Daten erheben wir, um einen reibungslosen
          Verbindungsaufbau der Website zu gewährleisten und eine komfortable
          Nutzung unserer Website durch die Nutzer zu ermöglichen. Zudem dient
          die Logdatei der Auswertung der Systemsicherheit und -stabilität sowie
          administrativen Zwecken. Rechtsgrundlage für die vorübergehende
          Speicherung der Daten bzw. der Logfiles ist Art. 6 Abs. 1 lit. f
          DSGVO.
          <br /> <br />
          Aus Gründen der technischen Sicherheit, insbesondere zur Abwehr von
          Angriffsversuchen auf unseren Webserver, werden diese Daten von uns
          kurzzeitig gespeichert. Anhand dieser Daten ist uns ein Rückschluss
          auf einzelne Personen nicht möglich. Nach spätestens werden die Daten
          durch Verkürzung der IP-Adresse auf sieben Tage Domainebene
          anonymisiert, sodass es nicht mehr möglich ist, einen Bezug zum
          einzelnen Nutzer herzustellen.
          <br /> <br />
          In anonymisierter Form werden die Daten daneben ggf. zu statistischen
          Zwecken verarbeitet. Eine Speicherung dieser Daten zusammen mit
          anderen personenbezogenen Daten des Nutzers, ein Abgleich mit anderen
          Datenbeständen oder eine Weitergabe an Dritte findet zu keinem
          Zeitpunkt statt.
        </Text>
        <Headline
          title="Kontaktformular und Kontaktaufnahme per E-Mail"
          as={"h2"}
          fontSize={25}
          width={580}
        />
        <Text>
          Wenn Sie uns per Kontaktformular oder E-Mail Anfragen zukommen lassen,
          werden Ihre Angaben aus dem Anfrageformular bzw. Ihrer E-Mail
          inklusive der von Ihnen dort angegebenen zwecks Vor- und Nachname
          Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns
          gespeichert. Die Angabe einer E-Mail- Adresse ist zur Kontaktangabe
          erforderlich, die Angabe Ihres Namens sowie Ihrer Telefonnummer ist
          freiwillig. Diese Daten geben wir in keinem Fall ohne Ihre
          Einwilligung weiter. Rechtsgrundlage für die Verarbeitung der Daten
          ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens
          gemäß Art. 6 Abs. 1 lit. f DSGVO sowie ggf. Art. 6 Abs. 1 lit. b
          DSGVO, sofern Ihre Anfrage auf den Abschluss eines Vertrages abzielt.
          Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage
          gelöscht, sofern keine gesetzlichen Aufbewahrungspflichten
          entgegenstehen. Sie können im Falle von Art. 6 Abs. 1 lit. f DSGVO
          gegen die Verarbeitung Ihrer personenbezogenen Daten jederzeit
          Widerspruch einlegen.
        </Text>
        <Headline
          title="Google Analytics"
          as={"h2"}
          fontSize={25}
          width={200}
        />
        <Text>
          Unsere Website nutzt Google Analytics, einen Internetanalysedienst,
          der von Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
          Irland ("Google") zur Verfügung gestellt wird. Google Analytics
          verwendet sogenannte "Cookies".
          <br /> <br />
          Google wird diese Informationen im Auftrag des Betreibers dieser
          Website benutzen, um Ihre Nutzung der Website auszuwerten und um
          Reports über die Websiteaktivitäten zu erstellen. Google wird diese
          Informationen auch dazu verwenden, dem Website-Betreiber weitere, mit
          der Nutzung der Website und des Internets verbundene, Dienstleistungen
          zu erbringen. Die von Ihrem Browser im Rahmen von Google Analytics
          gesendete IP-Adresse wird nicht mit anderen Daten von Google
          kombiniert. Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. a DSGVO
          auf der Grundlage der von Ihnen erteilten Einwilligung.
          <br /> <br />
          Wir verwenden Google Analytics nur mit aktivierter IP-Anonymisierung.
          Das bedeutet, Ihre IP-Adresse wird von Google nur gekürzt
          weiterverarbeitet. Wir haben mit dem Dienstleister einen
          Auftragsverarbeitungsvertrag geschlossen, in welchem wir ihn
          verpflichten, die Daten unserer Kunden zu schützen und sie nicht an
          Dritte weiterzugeben. Da eine Übertragung personenbezogener Daten in
          die USA erfolgt, sind weitere Schutzmechanismen erforderlich, die das
          Datenschutzniveau der DSGVO sicherstellen. Um dies zu gewährleisten,
          haben wir mit dem Anbieter Standarddatenschutzklauseln gemäß Art. 46
          Abs. 2 lit. c DSGVO vereinbart. Diese verpflichten den Empfänger der
          Daten in den USA die Daten entsprechend dem Schutzniveau in Europa zu
          verarbeiten. In Fällen, in denen dies auch durch diese vertragliche
          Erweiterung nicht sichergestellt werden kann, bemühen wir uns um
          darüberhinausgehende Regelungen und Zusagen des Empfängers in den USA.
          Die Nutzungsbedingungen von Google Analytics und Informationen zum
          Datenschutz können über die folgenden Links abgerufen werden:
          http://www.google.com/analytics/terms/de.html
          https://www.google.de/intl/de/policies/ Die Daten werden gelöscht,
          sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
          erforderlich sind. Eine Löschung der Daten auf Nutzer- und
          Ereignisebene, die mit Cookies, Nutzerkennungen (z. B. User-ID) und
          Werbe-IDs (z. B. DoubleClick-Cookies, Android-Werbe-ID, IDFA
          [Apple-Kennung für Werbetreibende]) verknüpft sind erfolgt spätestens
          14 Monate nach ihrer Erhebung. Sie können das Speichern von Cookies
          verhindern, indem Sie die Einstellungen Ihrer Browser-Software
          entsprechend anpassen. Wir weisen Sie jedoch darauf hin, dass Sie in
          diesem Fall gegebenenfalls nicht alle Funktionen dieser Website
          uneingeschränkt nutzen können. Sie können auch verhindern, dass Google
          die durch den Cookie erzeugten Daten sammelt und Ihre Nutzung der
          Website (einschließlich Ihrer IP-Adresse) analysiert und diese Daten
          durch Google verarbeitet, indem Sie das Browser-Plugin herunterladen
          und installieren, das unter verfügbar ist.
        </Text>
        <Headline title="Cookies" as={"h2"} fontSize={25} width={95} />
        <Text>
          Unsere Website setzt Cookies ein, die vom Browser auf Ihrem Gerät
          gespeichert werden und die bestimmte Einstellungen zur Nutzung der
          Website enthalten (z. B. zur laufende Sitzung). Cookies dienen dazu,
          unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
          Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden
          und die Ihr Browser speichert. Die meisten der von uns verwendeten
          Cookies sind so genannte Session-Cookies, welche nach dem Schließen
          des Browsers automatisch gelöscht werden. Andere Cookies bleiben auf
          Ihrem Endgerät gespeichert, bis Sie diese löschen oder die
          Speicherdauer abläuft. Diese Cookies ermöglichen es uns, Ihren Browser
          beim nächsten Besuch wiederzuerkennen.
          <br /> <br />
          Teilweise dienen die Cookies dazu, durch Speicherung von Einstellungen
          Websiteprozesse zu vereinfachen (z. B. das Vorhalten bereits
          ausgewählter Optionen). Sofern durch einzelne von uns implementierte
          Cookies auch personenbezogene Daten verarbeitet werden, erfolgt die
          Verarbeitung gemäß Art. 6 Abs. 1 lit. b DSGVO entweder zur
          Durchführung des Vertrages oder gemäß Art. 6 Abs. 1 lit. f DSGVO zur
          Wahrung unserer berechtigten Interessen an der bestmöglichen
          Funktionalität der Website sowie einer kundenfreundlichen und
          effektiven Ausgestaltung des Seitenbesuchs. Sie können Ihren Browser
          so einstellen, dass Sie über das Setzen von Cookies informiert werden
          und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für
          bestimmte Fälle oder generell ausschließen sowie das automatische
          Löschen der Cookies beim Schließen des Browsers aktivieren. Die Cookie
          Einstellungen können unter den folgenden Links für die jeweiligen
          Browser verwaltet werden.
        </Text>
        <UnorderedList pl={[5, 8, 15, 20]}>
          <ListItem>
            FireFox:{" "}
            <a
              rel="nofollow"
              href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen"
            >
              https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
            </a>
          </ListItem>
          <ListItem>
            Internet Explorer:{" "}
            <a
              rel="nofollow"
              href="http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies"
            >
              http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies
            </a>
          </ListItem>
          <ListItem>
            Chrome:{" "}
            <a
              rel="nofollow"
              href="http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647"
            >
              http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647
            </a>
          </ListItem>
          <ListItem>
            Safari:{" "}
            <a
              rel="nofollow"
              href="https://support.apple.com/kb/ph21411?locale=de_DE"
            >
              https://support.apple.com/kb/ph21411?locale=de_DE
            </a>
          </ListItem>
          <ListItem>
            Opera:{" "}
            <a
              rel="nofollow"
              href="https://help.opera.com/en/latest/web-preferences/#cookies"
            >
              https://help.opera.com/en/latest/web-preferences/#cookies
            </a>
          </ListItem>
        </UnorderedList>
        <Text>
          Sie können auch die Cookies vieler Unternehmen und Funktionen einzeln
          verwalten, die für Werbung eingesetzt werden. Verwenden Sie dazu die
          entsprechenden Nutzertools, abrufbar unter
          https://www.aboutads.info/choices/ oder Die meisten Browser bieten
          zudem eine sog.
          „Do-Not-http://www.youronlinechoices.com/uk/your-ad-choices
          Track-Funktion“ an, mit der Sie angeben können, dass Sie nicht von
          Websites „verfolgt“ werden möchten. Wenn diese Funktion aktiviert ist,
          teilt der jeweilige Browser Werbenetzwerken, Websites und Anwendungen
          mit, dass Sie nicht zwecks verhaltensbasierter Werbung und Ähnlichem
          verfolgt werden möchten. Informationen und Anleitungen, wie Sie diese
          Funktion bearbeiten können, erhalten Sie je nach Anbieter Ihres
          Browsers, unter den nachfolgenden Links:
        </Text>
        <UnorderedList pl={[5, 8, 15, 20]}>
          <ListItem>
            Google Chrome:{" "}
            <a
              rel="nofollow"
              href="https://support.google.com/chrome/answer/2790761?co=GENIE.Platform%3DDesktop&hl=de"
            >
              https://support.google.com/chrome/answer/2790761?co=GENIE.Platform%3DDesktop&hl=de
            </a>
          </ListItem>
          <ListItem>
            Mozilla Firefox:{" "}
            <a rel="nofollow" href="https://www.mozilla.org/de/firefox/dnt/">
              https://www.mozilla.org/de/firefox/dnt/
            </a>
          </ListItem>
          <ListItem>
            Internet Explorer:{" "}
            <a
              rel="nofollow"
              href="https://support.microsoft.com/de-de/help/17288/windows-internet-explorer-11-use-do-not-track"
            >
              https://support.microsoft.com/de-de/help/17288/windows-internet-explorer-11-use-do-not-track
            </a>
          </ListItem>
          <ListItem>
            Opera:{" "}
            <a
              rel="nofollow"
              href="http://help.opera.com/Windows/12.10/de/notrack.html"
            >
              http://help.opera.com/Windows/12.10/de/notrack.html
            </a>
          </ListItem>
          <ListItem>
            Safari:{" "}
            <a
              rel="nofollow"
              href="https://support.apple.com/kb/PH21416?locale=de_DE"
            >
              https://support.apple.com/kb/PH21416?locale=de_DE
            </a>
          </ListItem>
        </UnorderedList>
        <Text>
          Zusätzlich können Sie standardmäßig das Laden sog. Scripts verhindern.
          NoScript erlaubt das Ausführen von JavaScripts, Java und anderen
          Plug-ins nur bei vertrauenswürdigen Domains Ihrer Wahl. Informationen
          und Anleitungen, wie Sie diese Funktion bearbeiten können, erhalten
          Sie über den Anbieter Ihres Browsers (z. B. für Mozilla Firefox unter:
          https://addons.mozilla.org/de/firefox/addon/noscript/). Bitte beachten
          Sie, dass bei der Deaktivierung von Cookies die Funktionalität dieser
          Website eingeschränkt sein kann.
        </Text>
        <Headline
          title="Datenweitergabe und Empfänger"
          as={"h2"}
          fontSize={25}
          width={390}
        />
        <Text>
          Eine Übermittlung Ihrer personenbezogenen Daten an Dritte findet nicht
          statt, außer{" "}
        </Text>
        <UnorderedList pl={[5, 8, 15, 20]}>
          <ListItem>
            wenn wir in der Beschreibung der jeweiligen Datenverarbeitung
            explizit darauf hingewiesen haben.
          </ListItem>
          <ListItem>
            wenn Sie ausdrückliche Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a
            DSGVO dazu erteilt haben,
          </ListItem>
          <ListItem>
            die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein
            überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer
            Daten haben,
          </ListItem>
          <ListItem>
            im Falle, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c
            DSGVO eine gesetzliche Verpflichtung besteht und
          </ListItem>
          <ListItem>
            soweit dies nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung
            von Vertragsverhältnissen mit Ihnen erforderlich ist.
          </ListItem>
        </UnorderedList>
        <Text>
          Wir nutzen darüber hinaus für die Abwicklung unserer Services externe
          Dienstleister, die wir sorgfältig ausgewählt und schriftlich
          beauftragt haben. Sie sind an unsere Weisungen gebunden und werden von
          uns regelmäßig kontrolliert. Mit welchen wir erforderlichenfalls
          Auftragsverarbeitungsverträge gem. Art. 28 DSGVO geschlossen haben.
          Diese sind Dienstleister für das Webhosting, den Versand von E-Mails
          sowie Wartung und Pflege unserer IT-Systemen usw. Die Dienstleister
          werden diese Daten nicht an Dritte weitergeben.
        </Text>
        <Headline title="Datensicherheit" as={"h2"} fontSize={25} width={190} />
        <Text>
          Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des
          Stands der Technik, der Implementierungskosten und der Art, des
          Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
          unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos
          für die Rechte und Freiheiten natürlicher Personen, geeignete
          technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten. Diese Webseite nutzt aus
          Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher
          Inhalte eine SSL-Verschlüsselung.
        </Text>
        <Headline
          title="Dauer der Speicherung personenbezogener Daten"
          as={"h2"}
          fontSize={25}
          width={590}
        />
        <Text>
          {" "}
          Die Dauer der Speicherung von personenbezogenen Daten bemisst sich an
          den einschlägigen gesetzlichen Aufbewahrungsfristen (z. B. aus dem
          Handelsrecht und dem Steuerrecht). Nach Ablauf der jeweiligen Frist
          werden die entsprechenden Daten routinemäßig gelöscht. Sofern Daten
          zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind oder
          unsererseits ein berechtigtes Interesse an der Weiterspeicherung
          besteht, werden die Daten gelöscht, wenn Sie zu diesen Zwecken nicht
          mehr erforderlich sind oder Sie von Ihrem Widerrufs- oder
          Widerspruchsrecht Gebrauch gemacht machen.
        </Text>
        <Headline title="Ihre Rechte" as={"h2"} fontSize={25} width={130} />
        <Text>
          Im Folgenden finden Sie Informationen dazu, welche Betroffenenrechte
          das geltende Datenschutzrecht Ihnen gegenüber dem Verantwortlichen
          hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten gewährt:
          <br /> <br />
          Das Recht, gemäß Art. 15 DSGVO Auskunft über Ihre von uns
          verarbeiteten personenbezogenen Daten zu verlangen.
          <br />
          Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die
          Kategorie der personenbezogenen Daten, die Kategorien von Empfängern,
          gegenüber denen Ihre Daten offengelegt wurden oder werden, die
          geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
          Löschung, Einschränkung der Verarbeitung oder Widerspruch, das
          Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern
          diese nicht bei uns erhoben wurden, sowie über das Bestehen einer
          automatisierten Entscheidungsfindung einschließlich Profiling und ggf.
          aussagekräftigen Informationen zu deren Einzelheiten verlangen.
          <br /> <br />
          Das Recht, gemäß Art. 16 DSGVO unverzüglich die Berichtigung
          unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten
          personenbezogenen Daten zu verlangen.
          <br /> <br />
          Das Recht, gemäß Art. 17 DSGVO die Löschung Ihrer bei uns
          gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die
          Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und
          Information, zur Erfüllung einer rechtlichen Verpflichtung, aus
          Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen erforderlich ist.
          <br /> <br />
          Das Recht, gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung
          Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der
          Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist,
          Sie aber deren Löschung ablehnen und wir die Daten nicht mehr
          benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21
          DSGVO Widerspruch gegen die Verarbeitung eingelegt haben.
          <br /> <br />
          Das Recht, gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie
          uns bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
          anderen Verantwortlichen zu verlangen.
          <br /> <br />
          Das Recht, sich gemäß Art. 77 DSGVO bei einer Aufsichtsbehörde zu
          beschweren. In der Regel können Sie sich hierfür an die
          Aufsichtsbehörde des Bundeslandes unseres oben angegebenen Sitzes oder
          ggf. die Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes wenden.
          <br /> <br />
          Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO:
          Sie haben das Recht, eine einmal erteilte Einwilligung in die
          Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu
          widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten
          unverzüglich löschen, sofern eine weitere Verarbeitung nicht auf eine
          Rechtsgrundlage zur einwilligungslosen Verarbeitung gestützt werden
          kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der
          aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
          nicht berührt.
        </Text>
        <Headline
          title="Widerspruchsrecht"
          as={"h2"}
          fontSize={25}
          width={230}
        />
        <Text>
          Sofern Ihre personenbezogenen Daten von uns auf Grundlage von
          berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO
          verarbeitet werden, haben Sie gemäß Art. 21 DSGVO das Recht,
          Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten
          einzulegen, soweit dies aus Gründe erfolgt, die sich aus Ihrer
          besonderen Situation ergeben. Soweit sich der Widerspruch gegen die
          Verarbeitung personenbezogener Daten zum Zwecke von Direktwerbung
          richtet, haben Sie ein generelles Widerspruchsrecht ohne das
          Erfordernis der Angabe einer besonderen Situation. Möchten Sie von
          Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine
          E-Mail kontakt@kroll-bau.com .
        </Text>
        <Headline
          title="Externe Verlinkungen"
          as={"h2"}
          fontSize={25}
          width={250}
        />
        <Text>
          {" "}
          Soziale Netzwerke (Facebook, Twitter, Xing etc.) sind auf unserer
          Webseite lediglich als Link zu den entsprechenden Diensten
          eingebunden. Nach dem Anklicken des eingebundenen Text-/Bild-Links
          werden Sie auf die Seite des jeweiligen Anbieters weitergeleitet. Erst
          nach der Weiterleitung werden Nutzerinformationen an den jeweiligen
          Anbieter übertragen. Informationen zum Umgang mit Ihren
          personenbezogenen Daten bei Nutzung dieser Webseiten entnehmen Sie
          bitte den jeweiligen Datenschutzbestimmungen der von Ihnen genutzten
          Anbieter.
        </Text>
        <Headline
          title="Änderungsvorbehalt"
          as={"h2"}
          fontSize={25}
          width={240}
        />
        <Text>
          {" "}
          Wir behalten uns vor, diese Datenschutzerklärung erforderlichenfalls
          unter Beachtung der geltenden Datenschutzvorschriften anzupassen bzw.
          zu aktualisieren. Auf diese Weise können wir sie den aktuellen
          rechtlichen Anforderungen anpassen und Änderungen unserer Leistungen
          berücksichtigen, z. B. bei der Einführung neuer Services. Für Ihren
          Besuch gilt die jeweils aktuellste Fassung.
          <br />
          <br />
          Diese Datenschutzerklärung wurde erstellt durch
          www.datenschutzexperte.de
          <br />
          <br />
          Stand dieser Datenschutzerklärung: 6.11.2022
        </Text>
      </Stack>
    </Box>
  );
}
