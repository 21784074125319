import { Box, useBreakpointValue, SimpleGrid, HStack, Center } from "@chakra-ui/react";
import { Header } from "../components/Header";
import { RenderBlogArticelPreview } from "../components/blog/RenderBlogArticelPreview";
import { filterArticles } from "../components/blog/BlogContent";
import { BlogSideBar } from "../components/blog/BlogSideBar";
import { useState } from "react";
import { BlogFilterIcon } from "../components/blog/BlogFilterIcon";

export function Blog(props) {
  const { setShoppingCart, shoppingCart } = props;
  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  const [filter, setFilter] = useState("");

  return (
    <Box>
      <Header />
      <BlogFilterIcon setFilter={(value) => setFilter(value)} py={3} />
      <Center>
        <HStack spacing={0} alignItems={"flex-start"} pb={10} maxW={1400}>
          <Box>
            <SimpleGrid spacing={5} mx={5} columns={[1, 1, 2, 2]}>
              {filterArticles(filter).map((article, index) => {
                return (
                  <Box key={index}>
                    <RenderBlogArticelPreview article={article} />
                  </Box>
                );
              })}
            </SimpleGrid>
          </Box>
          {(variant === "md" || variant === "lg") && (
            <Box pr={variant === "md" ? 5 : 5}>
              <BlogSideBar
                iconSize={30}
                iconBoxSize={45}
                setFilter={(value) => setFilter(value)}
                maxW={450}
              />
            </Box>
          )}
        </HStack>
      </Center>
    </Box>
  );
}
