import fassadensanierung from "../images/bricklaying/fassadensanierung.jpeg";
import fensterbau from "../images/bricklaying/fensterbau.webp";
import wanddurchbruch from "../images/bricklaying/wanddurchbruch.jpeg";
import verklinkern from "../images/bricklaying/verklinkern.webp";
import fundament from "../images/concrete/fundament.jpeg";
import sanierung from "../images/concrete/sanierung.jpeg";

export const articles = [
  {
    header: "Dienstleistungen rund um Fassadensanierung",
    pictureLink: fassadensanierung,
    pictureAlt: "Dienstleistungen rund um Fassadensanierung",
    link: "/blog/cbd-fuer-tiere",
    categories: ["Maurerarbeiten"],
    releaseDate: new Date(),
    viewCount: 187,
    comments: [],
  },
  {
    header: "Dienstleistungen rund um Fensterbau",
    pictureLink: fensterbau,
    pictureAlt: "Dienstleistungen rund um Fensterbau",
    link: "/blog/cbd-fuer-tiere2",
    categories: ["Maurerarbeiten"],
    releaseDate: new Date(),
    viewCount: 187,
    comments: [],
  },
  {
    header: "Dienstleistungen rund um Wanddurchbrüche",
    pictureLink: wanddurchbruch,
    pictureAlt: "Dienstleistungen rund um Wanddurchbrüche",
    link: "/blog/cbd-fuer-tiere3",
    categories: ["Maurerarbeiten"],
    releaseDate: new Date(),
    viewCount: 187,
    comments: [],
  },
  {
    header: "Dienstleistungen rund ums Verklinkern von Wänden",
    pictureLink: verklinkern,
    pictureAlt: "Dienstleistungen rund ums Verklinkern von Wänden",
    link: "/blog/cbd-fuer-tiere4",
    categories: ["Maurerarbeiten"],
    releaseDate: new Date(),
    viewCount: 187,
    comments: [],
  },
  {
    header: "Dienstleistungen rund um Stahl- Betonbau",
    pictureLink: fundament,
    pictureAlt: "Dienstleistungen rund um Stahl- Betonbau",
    link: "/blog/cbd-richtig-lagern5",
    categories: ["Stahl- Betonbau"],
    releaseDate: new Date(),
    viewCount: 420,
    comments: [],
  },
  {
    header: "Dienstleistungen rund um Beton Sanierung",
    pictureLink: sanierung,
    pictureAlt: "Dienstleistungen rund um Beton Sanierung",
    link: "/blog/beste-cbd",
    categories: ["Stahl- Betonbau"],
    releaseDate: new Date(),
    viewCount: 420,
    comments: [],
  },
];

export const categories = () => {
  let categorieList = [];
  for (let i = 0; i < articles.length; i++) {
    for (let j = 0; j < articles[i].categories.length; j++) {
      if (categorieList.indexOf(articles[i].categories[j]) === -1) {
        categorieList.push(articles[i].categories[j]);
      }
    }
  }
  return categorieList;
};

export const filterArticles = (filter) => {
  let filtered = [];
  if (filter === "") {
    filtered = articles;
  } else {
    for (let i = 0; i < articles.length; i++) {
      for (let j = 0; j < articles[i].categories.length; j++) {
        if (articles[i].categories.indexOf(filter) !== -1) {
          filtered.push(articles[i]);
        }
      }
    }
  }
  return filtered;
};
