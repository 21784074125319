import {
  Image,
  Box,
  HStack,
  Text,
  Stack,
  Spacer,
  Center,
  useDisclosure,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import logo from "./images/logo/logo.png";
import logo_left from "./images/logo/logo_left.png";
import logo_right from "./images/logo/logo_right.png";
import { mainColor } from "./mainColor";
import { BurgerMenu } from "./BurgerMenu";
import bauplan from "./images/bauplan2.jpg";
import { CurrentlyInProgress } from "./CurrentlyInProgress";
import maurer from "./images/icons/maurer-arbeiten.png";
import stahl from "./images/icons/stahl-bau.png";
import fliesen from "./images/icons/fliesen-arbeiten.png";
import { mainSpacing } from "./mainSpacing";

const HeaderImage = (props) => {
  return (
    <Box
      h={["150px", "200px", "260px", "18.2vw"]}
      backgroundSize={"100% 100%"}
      backgroundImage={bauplan}
    />
  );
};

const headerHeight = "45px";

const services = [
  {
    name: "Maurerarbeiten",
    iconSmall: <Image src={maurer} w={"25px"} />,
    link: "/maurerarbeiten",
  },
  {
    name: "Fliesenarbeiten",
    iconSmall: <Image src={fliesen} w={"25px"} />,
    link: "/fliesenarbeiten",
  },
  {
    name: "Stahl-Betonbau",
    iconSmall: <Image src={stahl} w={"25px"} />,
    link: "/stahl-betonbau",
  },
];

export function Header({ children, ...rest }) {
  const {
    isOpen: isOpenProgress,
    onOpen: onOpenProgress,
    onClose: onCloseProgress,
  } = useDisclosure();

  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  const ServiceMenu = () => {
    const {
      isOpen: isOpenThis,
      onOpen: onOpenThis,
      onClose: onCloseThis,
    } = useDisclosure();

    return (
      <Box
        h={headerHeight}
        onMouseLeave={onCloseThis}
        zIndex={2}
        onMouseEnter={onOpenThis}
      >
        <Spacer />
        <Menu
          borderRadius={0}
          isOpen={isOpenThis}
          matchWidth={true}
          spacing={0}
        >
          <MenuButton
            h={headerHeight}
            variant={"unstyled"}
            fontWeight="black"
            fontSize={[15, 14, 16, 20]}
            transition="all 0.2s"
            borderRadius="md"
            borderWidth="0px"
            _expanded={{ bg: mainColor }}
            _focus={{ boxShadow: "none" }}
          >
            LEISTUNGEN
          </MenuButton>
          <MenuList
            minW={"0px"}
            px={3}
            w={"210px"}
            ml={-6}
            mt={-2}
            borderRadius={0}
          >
            {services.map((service, index) => {
              return (
                <Box
                  key={index}
                  bgColor="white"
                  onClick={() => onOpenProgress()}
                  w={"195px"}
                >
                  <MenuItem
                    color="black"
                    w={"190px"}
                    textAlign="center"
                    _hover={{ color: mainColor, bgColor: "white" }}
                    bgColor="white"
                    fontSize={[12, 12, 19, 19]}
                  >
                    <Text textAlign="center">{service.name.toUpperCase()}</Text>
                  </MenuItem>
                </Box>
              );
            })}
          </MenuList>
        </Menu>
        <Spacer />
      </Box>
    );
  };

  const links = [
    { name: "START", link: "/" },
    { name: "GALERIE", link: "/galerie", disabled: true },
    { name: "REFERENZEN", link: "/bewertungen" },
    { name: <ServiceMenu /> },
    { name: "JOBS", link: "/jobs" },
    /*     { name: "BLOG", link: "/blog" }, */
    { name: "KONTAKT", link: "/kontakt" },
  ];

  return (
    <>
      <CurrentlyInProgress
        isOpen={isOpenProgress}
        onOpen={onOpenProgress}
        onClose={onCloseProgress}
      />
      <Stack spacing={0} {...rest}>
        <Stack
          spacing={[4, 7, 7, 7]} /* pt={["35px", "35px", "30px", "65px"]} */
        >
          <Box w={20} />
          <Stack spacing={4} h={"100%"} px={mainSpacing}>
            {variant === "lg" ? (
              <HStack h={"100%"} alignItems={"flex-end"}>
                <a href="/">
                  <Image
                    cursor={"pointer"}
                    w={"31vw"}
                    /* maxW={500} */ src={logo}
                  />
                </a>
                <Spacer />
                <HStack
                  fontWeight={"black"}
                  textAlign="right"
                  fontSize={[14, 15, 18, 22]}
                  h={"20px"}
                >
                  <Text
                    cursor="pointer"
                    onClick={() => (window.location = "tel:017641947756")}
                  >
                    0176 419 477 56
                  </Text>{" "}
                  <Text px={2}>oder</Text>
                  <Text
                    cursor="pointer"
                    onClick={() => (window.location = "tel:043434390470")}
                  >
                    04343 439 047 0
                  </Text>
                </HStack>
              </HStack>
            ) : (
              <>
                {variant === "md" ? (
                  <HStack h={"100%"}>
                    <Stack>
                      <Spacer />
                      <a href="/">
                        <Image
                          w={"30vw"}
                          minWidth={350}
                          src={logo}
                          cursor={"pointer"}
                        />
                      </a>
                      <Spacer />
                    </Stack>

                    <Spacer />
                    <Box h={"60px"}></Box>
                  </HStack>
                ) : (
                  <HStack align={"flex-start"} w={"100%"}>
                    <a href="/">
                      <Image
                        maxH={"100px"}
                        src={logo_left}
                        cursor={"pointer"}
                      />
                    </a>
                    <Spacer />
                    <a href="/">
                      <Image
                        maxH={"100px"}
                        src={logo_right}
                        cursor={"pointer"}
                      />
                    </a>
                    <Spacer />
                    {variant === "base" || variant === "sm" ? (
                      <BurgerMenu pt={1.5} />
                    ) : (
                      <></>
                    )}
                  </HStack>
                )}
              </>
            )}
          </Stack>
          <Box bgColor={mainColor} height={headerHeight} px={mainSpacing}>
            <Center h={"100%"} w={"100%"}>
              <HStack
                fontWeight={"black"}
                w={"100%"}
                color="white"
                fontSize={[15, 14, 16, 20]}
              >
                <Text
                  cursor={"pointer"}
                  zIndex={2}
                  fontSize={[15, 14, 16, 20]}
                  onClick={() =>
                    (window.location = "mailto:info@krall-bau.com")
                  }
                >
                  INFO@KROLL-BAU.COM
                </Text>
                <Spacer />
                {variant !== "base" && variant !== "sm" ? (
                  <HStack spacing={5}>
                    {links.map((link, i) => {
                      return (
                        <Box
                          key={i}
                          cursor={"pointer"}
                          onClick={() =>
                            link.disabled === true && onOpenProgress()
                          }
                        >
                          <a href={link.disabled !== true && link.link}>
                            <Text key={i}>{link.name}</Text>
                          </a>
                        </Box>
                      );
                    })}
                  </HStack>
                ) : (
                  <>
                    {variant === "sm" && (
                      <HStack>
                        <HStack
                          fontWeight={"extrabold"}
                          fontSize={[14, 14, 18, 20]}
                        >
                          <Text
                            cursor="pointer"
                            onClick={() =>
                              (window.location = "tel:017641947756")
                            }
                          >
                            017641947756
                          </Text>{" "}
                          <Text px={2}>oder</Text>
                          <Text
                            cursor="pointer"
                            onClick={() =>
                              (window.location = "tel:043434390470")
                            }
                          >
                            043434390470
                          </Text>
                        </HStack>
                      </HStack>
                    )}
                  </>
                )}
              </HStack>
            </Center>
          </Box>
        </Stack>
        {window.location.pathname === "/" && <HeaderImage />}
        {children}
      </Stack>
    </>
  );
}
