import { HStack, Stack, Image, Text, Spacer, useBreakpointValue } from "@chakra-ui/react";
import { useContext } from "react";
import { AppContext } from "../AppContext";

export function ContentCard(props) {
  const { picture, alt, title, text, id } = props;
  const variant = useBreakpointValue({ sm: "sm", md: "md" });
  const [reference, setReference] = useContext(AppContext);
  return (
    <>
      {variant === "md" ? (
        <HStack key={id} alignItems={"flex-start"}>
          {id % 2 !== 0 ? (
            <Stack h={"100%"}>
              <Spacer />
              <Image
                w={["30vw", "30vw", "40vw", "30vw"]}
                src={picture}
                alt={alt}
              />
              <Spacer />
            </Stack>
          ) : (
            <Stack w={"50vw"} pl={["10vw", "10vw", "5vw", "10vw"]}>
              <Text fontWeight="extrabold" fontSize={25}>
                {title}
              </Text>
              {text}
              <br />
              {/*               <a href={"/referenzen"}>
                <Button
                  bgColor={mainColor}
                  _hover={{ bgColor: mainColor }}
                  color="white"
                  fontWeight={"extrabold"}
                  fontSize={22}
                  onClick={() => {
                    setReference({ title: title, images: [picture] });
                  }}
                >
                  REFERENZEN
                </Button>
              </a> */}
            </Stack>
          )}
          <Spacer />
          {id % 2 === 0 ? (
            <Stack h={"100%"}>
              <Spacer />
              <Image
                w={["30vw", "30vw", "40vw", "30vw"]}
                src={picture}
                alt={alt}
                /* borderLeftRadius={20} */
              />
              <Spacer />
            </Stack>
          ) : (
            <Stack w={"50vw"} pr={("10vw", "10vw", "5vw", "10vw")}>
              <Text fontWeight="extrabold" fontSize={25}>
                {title}
              </Text>
              {text}
              <br />
              {/* 
               <a href={"/referenzen"}>
              <Button
                bgColor={mainColor}
                _hover={{ bgColor: mainColor }}
                color="white"
                fontWeight={'extrabold'}
                fontSize={22}
                onClick={() => {
                  setReference({ title: title, images: [picture] });
                }}
              >
                REFERENZEN
              </Button> </a> */}
            </Stack>
          )}
        </HStack>
      ) : (
        <Stack>
          <HStack>
            {id % 2 !== 0 && variant === "sm" && <Spacer />}
            <Stack h={"100%"}>
              <Spacer />
              <Image
                w={["100vw", "70vw", "40vw", "30vw"]}
                src={picture}
                alt={alt}
                /*                 borderRightRadius={id % 2 === 0 && variant === "sm" ? 20 : 0}
                              borderLeftRadius={id % 2 !== 0 && variant === "sm" ? 20 : 0} */
              />
              <Spacer />
            </Stack>
            {id % 2 === 0 && variant === "sm" && <Spacer />}
          </HStack>
          <Stack px={3}>
            <Text fontWeight="extrabold" fontSize={25}>
              {title}
            </Text>
            {text}
            <br />
            {/*  <a href={"/referenzen"}> <Button
              bgColor={mainColor}
              _hover={{ bgColor: mainColor }}
              color="white"
              fontWeight={'extrabold'}
              fontSize={22}
              onClick={() => {
                setReference({ title: title, images: [picture] });
              }}
            >
              REFERENZEN
            </Button>  </a> */}
          </Stack>
        </Stack>
      )}
    </>
  );
}
