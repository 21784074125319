import {
  Box,
  Stack,
  Text,
  Image,
  useBreakpointValue,
  HStack,
  Center,
  Spacer,
  Wrap,
  WrapItem,
  Button,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { mainColor } from "./mainColor";
import { useFilePicker } from "use-file-picker";
import React, { useEffect } from "react";
import { DownloadIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { newQuestions, Anwsers } from "./projectPlanerData";
import { ShowOffer } from "./ShowOffer";
import { Textarea } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { useContext } from "react";
import { PlanerContext } from "../PlanerContext";

export function ProjectPlaner({ start, ...rest }) {
  const [state, setState] = useState(start);
  const [choices, setChoices] = useState([]);
  const [picks, setPicks] = useState([]);
  const [test, setTest] = useState(true);
  const [customTiles, setTiles] = useState([{ l: 20, h: 20 }]);
  const variant = useBreakpointValue({ base: "base", sm: "sm" });
  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: true,
    limitFilesConfig: { max: 5 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 50,
    imageSizeRestrictions: {
      maxHeight: 30000, // in pixels
      maxWidth: 30000,
      minHeight: 0,
      minWidth: 0,
    },
  });
  const [newAnwsers, setAnwsers] = useContext(PlanerContext);

  useEffect(() => {
    setAnwsers(Anwsers);
  }, []);

  const {
    register,
    getValues,
    reset,
    formState: { dirtyFields },
  } = useForm();

  const PickItem = (props) => {
    const { a, index } = props;

    return (
      <WrapItem key={index} color="white">
        <Box
          w={variant === "base" ? 170 : 200}
          h={variant === "base" ? 120 : 130}
          bgColor={mainColor}
          borderRadius={10}
          cursor={"pointer"}
          onClick={() => {
            choices.push({
              question: String(newQuestions[state]),
              anwser: a.anwser,
            });
            setChoices(choices);
            setState(a.next);
          }}
        >
          <Stack h={"100%"} fontSize={variant === "base" ? 12 : 15}>
            <Center pt={3} h={"100%"}>
              {a.icon}
            </Center>
            <Spacer />
            <Text pb={3} textAlign={"center"}>
              {" "}
              {a.anwser}{" "}
            </Text>
          </Stack>
        </Box>
      </WrapItem>
    );
  };

  const PickMultipleItem = (props) => {
    const { a, index } = props;

    return (
      <WrapItem key={index} color="white">
        <Box
          w={variant === "base" ? 170 : 200}
          h={variant === "base" ? 120 : 130}
          bgColor={picks.includes("   " + a.anwser) ? "green.700" : mainColor}
          borderRadius={10}
          cursor={"pointer"}
          onClick={() => {
            if (picks.includes("   " + a.anwser)) {
              const index = picks.indexOf("   " + a.anwser);
              if (index > -1) {
                picks.splice(index, 1);
              }
              setPicks(picks);
            } else {
              picks.push("   " + a.anwser);
              setPicks(picks);
            }
            setTest(!test);
          }}
        >
          <Stack h={"100%"} fontSize={variant === "base" ? 12 : 15}>
            <Center pt={3} h={"100%"}>
              {a.icon}
            </Center>
            <Spacer />
            <Text pb={3} textAlign={"center"}>
              {" "}
              {a.anwser}{" "}
            </Text>
          </Stack>
        </Box>
      </WrapItem>
    );
  };

  const TextItem = (props) => {
    const { a, index } = props;
    return (
      <form id="contact-form" noValidate>
        <WrapItem key={index} color="white">
          <Textarea
            h={150}
            w={"50vw"}
            color="black"
            /*   onChange={e => setText(e.target.value)} */
            minW={370}
            placeholder={a.placeholder}
            {...register("message", {
              required: true,
            })}
          />
        </WrapItem>
      </form>
    );
  };

  const DateItem = (props) => {
    const { a, index } = props;
    var curr = new Date();
    curr.setDate(curr.getDate());
    var last = new Date();
    last.setDate(curr.getDate() + 7);
    var date = curr.toISOString().substring(0, 10);
    return (
      <form id="contact-form" noValidate>
        <WrapItem key={index} color="white">
          <HStack>
            <Stack>
              <Text
                fontWeight="bold"
                fontSize={16}
                textAlign={"center"}
                color="black"
              >
                {" "}
                frühestens ab ...
              </Text>
              <Input
                type="date"
                w={170}
                color="black"
                defaultValue={date}
                placeholder={a.placeholder}
                {...register("start", {
                  required: true,
                })}
              />
            </Stack>
            <Stack>
              <Text
                fontWeight="bold"
                fontSize={16}
                textAlign={"center"}
                color="black"
              >
                {" "}
                spätestens bis ...
              </Text>
              <Input
                type="date"
                w={170}
                color="black"
                defaultValue={last}
                placeholder={a.placeholder}
                {...register("end", {
                  required: true,
                })}
              />
            </Stack>
          </HStack>
        </WrapItem>
      </form>
    );
  };

  const ImageUpload = (props) => {
    const { index } = props;

    if (loading) {
      return <div>Lädt...</div>;
    }

    if (errors.length) {
      return <div>Es ist ein Fehler aufgetreten...</div>;
    }

    return (
      <Box key={index}>
        <Center py={5}>
          <Button
            bgColor={mainColor}
            _hover={mainColor}
            color="white"
            rightIcon={<DownloadIcon />}
            onClick={() => openFileSelector()}
          >
            Bild auswählen
          </Button>
        </Center>
        <Wrap justify={"center"}>
          <br />
          {filesContent.map((file, index) => (
            <Stack key={index}>
              <Text fontSize={13} textAlign={"center"}>
                {file.name}
              </Text>
              <Center>
                <Image alt={file.name} maxW={"20vw"} src={file.content}></Image>
              </Center>
            </Stack>
          ))}
        </Wrap>
      </Box>
    );
  };

  const AddCustomTile = (props) => {
    const { index } = props;
    const InputField = (props) => {
      const { l, h } = props;
      const format = (val) => val + " cm";
      const parse = (val) => val.replace(/^\$/, "");

      const [value, setValue] = React.useState("0");

      const [length, setLength] = useState(l);
      const [height, setHeight] = useState(h);
      const [random, setRandom] = useState(true);

      function addTile() {
        newAnwsers[index].push({
          anwser: `${length}x${height}cm`,
          multiple: true,
          tile: true,
          next: 15,
          prev: 13,
          icon: <Text fontSize={25}> {`${length}x${height}cm`}</Text>,
        });
        setAnwsers([...newAnwsers]);
        setLength(20);
        setHeight(20);
      }

      return (
        <Center>
          <HStack alignItems={"flex-end"} /* w={300} */>
            <Stack>
              <Text textAlign={"center"} fontWeight={"bold"}>
                {" "}
                Länge
              </Text>

              <NumberInput
                onChange={(valueString) => setLength(parse(valueString))}
                value={format(length)}
              >
                <NumberInputField
                  step={5}
                  /*     placeholder={l} */
                  min={0}
                  /* onChange={(e) => setLength(e.target.value)} */
                  w={100}
                />

                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Stack>
            <Spacer />
            <Stack>
              <Text textAlign={"center"} fontWeight={"bold"}>
                {" "}
                Breite
              </Text>

              <NumberInput
                onChange={(valueString) => setHeight(parse(valueString))}
                value={format(height)}
              >
                <NumberInputField
                  step={5}
                  placeholder={h}
                  min={0}
                  onChange={(e) => setHeight(e.target.value)}
                  w={100}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Stack>
            <Button
              /* w={50} */ size="sm"
              bgColor={mainColor}
              onClick={() => addTile()}
              color="white"
            >
              +
            </Button>
          </HStack>
        </Center>
      );
    };

    return (
      <Box>
        <Stack>
          <Text fontWeight={"extrabold"} fontSize={20} textAlign={"center"}>
            {" "}
            Füge eigene Maße hinzu{" "}
          </Text>
          {customTiles.map((tile, index) => {
            return (
              <InputField key={index} h={tile.h} index={index} l={tile.l} />
            );
          })}
        </Stack>
      </Box>
    );
  };

  return (
    <>
      <Box {...rest}>
        <Center>
          <Stack>
            {state !== "finished" ? (
              <>
                <Text
                  fontWeight="extrabold"
                  fontSize={28}
                  maxWidth={"95vw"}
                  textAlign={"center"}
                >
                  {" "}
                  Wie können wir Sie unterstützen?{" "}
                </Text>
                <Text
                  maxWidth={"95vw"}
                  textAlign={"center"}
                  fontWeight={"bold"}
                  fontSize={20}
                >
                  {" "}
                  {newQuestions[state]}{" "}
                </Text>
                <Center>
                  <Wrap
                    fontSize={15}
                    fontWeight="bold"
                    justify="center"
                    maxW={1000}
                    pb={5}
                  >
                    {newAnwsers[state]?.map((a, index) => {
                      if (a.multiple === true) {
                        return (
                          <PickMultipleItem key={index} index={index} a={a} />
                        );
                      } else {
                        if (a.text === true) {
                          return <TextItem key={index} index={index} a={a} />;
                        } else {
                          if (a.date === true) {
                            return <DateItem key={index} index={index} a={a} />;
                          } else {
                            if (a.image === true) {
                              return (
                                <ImageUpload key={index} index={index} a={a} />
                              );
                            } else {
                              return (
                                <PickItem key={index} index={index} a={a} />
                              );
                            }
                          }
                        }
                      }
                    })}
                  </Wrap>
                </Center>
                {newAnwsers[state][0]?.tile === true && (
                  <AddCustomTile index={state} />
                )}
                <Center>
                  {state !== start && (
                    <HStack>
                      <Button
                        /* w={50} */
                        h={50}
                        color="white"
                        bgColor={mainColor}
                        _hover={{ bgColor: mainColor }}
                        leftIcon={<ArrowBackIcon fontSize={40} />}
                        onClick={() => {
                          setPicks([]);
                          reset();
                          setState(newAnwsers[state][0].prev);
                        }}
                      >
                        Zurück
                      </Button>
                      {(picks.length !== 0 ||
                        newAnwsers[state][0].text === true ||
                        dirtyFields.end) /* ||
                        filesContent[0]?.content ||
                        filesContent[0]?.content === undefined */ && (
                        <Button
                          /* w={50} */
                          h={50}
                          color="white"
                          bgColor={mainColor}
                          _hover={{ bgColor: mainColor }}
                          rightIcon={<ArrowForwardIcon fontSize={40} />}
                          onClick={() => {
                            choices.push({
                              question: String(newQuestions[state]),
                              anwser:
                                newAnwsers[state][0]?.multiple === true
                                  ? picks
                                  : newAnwsers[state][0]?.text === true
                                    ? "   " + getValues("message")
                                    : newAnwsers[state][0].image === true
                                      ? filesContent
                                      : [
                                          "   zwischen " + getValues("start"),
                                          " und " + getValues("end"),
                                        ],
                            });
                            setChoices(choices);
                            setState(newAnwsers[state][0].next);
                            setPicks([]);
                            reset();
                          }}
                        >
                          Weiter
                        </Button>
                      )}
                    </HStack>
                  )}
                  {/*    <Spacer /> */}
                  {state === start && (
                    <Text
                      fontSize={18}
                      fontWeight={"bold"}
                      textAlign={"center"}
                    >
                      {" "}
                      Ist der Bereich den Sie brauchen nicht dabei? Melden Sie
                      sich trotzdem bei uns <br /> Wir arbeiten
                      gewerkeübergreifend mit lokalen Handwerksbetrieben
                      zusammen{" "}
                    </Text>
                  )}
                </Center>
              </>
            ) : (
              <ShowOffer anwsers={choices} />
            )}
          </Stack>
        </Center>
      </Box>
    </>
  );
}
