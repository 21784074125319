import { Text, HStack, Spacer } from "@chakra-ui/react";

export function Footer(props) {
  const { ...rest } = props;
  return (
    <>
      <HStack
        position={"absolute"}
        bottom={3}
        left={0}
        px={[1, 1, 12, 12]}
        {...rest}
      >
        <a href="/impressum">
          <Text color="gray.600" cursor={"pointer"}>
            {" "}
            Impressum{" "}
          </Text>
        </a>
        <Spacer />
      </HStack>
      <HStack position={"absolute"} bottom={3} right={0} px={[1, 1, 12, 12]}>
        <Spacer />
        <a href="/datenschutz">
          <Text color="gray.600" cursor={"pointer"}>
            {" "}
            Datenschutz
          </Text>
        </a>
      </HStack>
    </>
  );
}
