import {
  Box,
  Image,
  Text,
  Icon,
  Center,
  HStack,
  Button,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ViewIcon, ChatIcon } from "@chakra-ui/icons";
import { mainColor } from "../mainColor";

export function RenderBlogArticelPreview(props) {
  const { article } = props;
  return (
    <Box maxW={450}>
      <Stack>
        <a href={article.link}>
          <Image
            borderRadius={20}
            cursor={"pointer"}
            src={article.pictureLink}
            position={"relative"}
            objectFit={"cover"}
            maxH={250}
          />
        </a>
        <a href={article.link}>
          <Box>
            <Text fontWeight={"extrabold"} cursor={"pointer"} fontSize={18}>
              {" "}
              {article.header}{" "}
            </Text>
          </Box>
        </a>
        <Stack>
          <a href={article.link}>
            <Button
              w={150}
              borderColor={"black"}
              borderWidth={2}
              borderRadius={"lg"}
              bgColor={"white"}
              _hover={{ bgColor: mainColor, color: "white" }}
            >
              {" "}
              Artikel lesen{" "}
            </Button>
          </a>
          <HStack spacing={3}>
            <Text color="gray.500">20.04.2022</Text>
            <HStack>
              <ViewIcon />
              <Text color="gray.500">{article.viewCount}</Text>
            </HStack>
            <HStack>
              <ChatIcon />
              <Text color="gray.500">{article.comments.length}</Text>
            </HStack>
          </HStack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default RenderBlogArticelPreview;
