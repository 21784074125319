export const jobs = [
  {
    name: "Ausbildung zum Maurer oder Fliesenleger (m/w/d)",
    qualifications: [
      "Handwerkliches Geschick und technisches Interesse",
      "Räumliches Vorstellungsvermögen",
      "Sorgfältiges und umsichtiges Arbeiten",
      "Flexibilität und Pünktlichkeit",
      "Hohes Maß an Einsatzbereitschaft und Teamfähigkeit",
      "Spaß an körperlichem Einsatz",
      "Gute Kenntnisse in Mathematik",
    ],
    benefits: [
      "Wir bilden aus, um zu übernehmen: Sehr gute Übernahmechancen",
      "Viele Möglichkeiten der persönlichen Weiterentwicklung",
      "Nette, hilfsbereite Kollegen mit ebenso viel Engagement und Motivation",
      "Moderne Arbeitsweisen nach neuester Technik",
      "Renommierter Arbeitgeber: Kroll Bauausführungen ist ein etabliertes Unternehmen in und um Kiel",
      "Praktikum möglich",
    ],
  },
  {
    name: "Maurer oder Fliesenleger (m/w/d)",
    qualifications: [
      "Abgeschlossene Berufsausbildung als Maurer/-in oder Fliesenleger/-in",
      "Handwerkliches Geschick, räumliches Vorstellungsvermögen",
      "Selbstständige und sorgfältige Arbeitsweise",
      "Hohes Maß an Einsatzbereitschaft und Teamfähigkeit",
      "Führerschein Klasse B",
    ],
    benefits: [
      "Faire Vergütung",
      "Viele Möglichkeiten der persönlichen Weiterentwicklung",
      "Nette, hilfsbereite Kollegen mit ebenso viel Engagement und Motivation",
      "Moderne Arbeitsweisen nach neuester Technik",
      "Firmenfahrzeug mit 1-Prozent-Regelung",
      "Renommierter Arbeitgeber: Kroll Bauausführungen ist ein etabliertes Unternehmen in und um Kiel",
      "Probearbeiten möglich",
    ],
  },
  {
    name: "Hochbaufacharbeiter oder Bauhelfer (m/w/d)",
    qualifications: [
      "Abgeschlossene Berufsausbildung als Hochbaufacharbeiter/-in oder Erfahrung als Bauhelfer",
      "Handwerkliches Geschick, räumliches Vorstellungsvermögen",
      "Sorgfältiges und umsichtiges Arbeiten",
      "Selbstständige und sorgfältige Arbeitsweise",
      "Hohes Maß an Einsatzbereitschaft und Teamfähigkeit",
    ],
    benefits: [
      "Faire Vergütung",
      "Viele Möglichkeiten der persönlichen Weiterentwicklung",
      "Nette, hilfsbereite Kollegen mit ebenso viel Engagement und Motivation",
      "Moderne Arbeitsweisen nach neuester Technik",
      "Firmenfahrzeug mit 1-Prozent-Regelung",
      "Renommierter Arbeitgeber: Kroll Bauausführungen ist ein etabliertes Unternehmen in und um Kiel",
      "Probearbeiten möglich",
    ],
  },
];
