import { Box, Button, Text } from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";

export function ExpandableText(props) {
  const { children, noOfLines = 4 } = props;
  const [expandedCount, setExpandedCount] = useState(noOfLines);
  const ref = useRef(null);

  const handleToggle = () => {
    setExpandedCount(expandedCount ? undefined : noOfLines);
  };

  const [lines, setLines] = useState(0);
  useEffect(() => {
    const element = document.getElementById("text");
    setLines(getLinesCount(element));
  });

  function getLinesCount(element) {
    var prevLH = 25;
    let height = ref.current.clientHeight;
    let width = ref.current.clientWidth;
    let lineCount = height / 25;
    return lineCount;
  }

  return (
    <Box display="inline-block" as="span">
      <Text lineHeight={"25px"} ref={ref} noOfLines={expandedCount} id={"text"}>
        {children}
      </Text>
      {lines > 3 && (
        <Button
          size="sm"
          variant="link"
          fontWeight="bold"
          colorScheme="slate"
          textDecoration="underline"
          onClick={handleToggle}
        >
          {!expandedCount ? "weniger anzeigen" : "mehr anzeigen"}
        </Button>
      )}
    </Box>
  );
}
