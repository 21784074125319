import { Stack, Text, Box } from "@chakra-ui/react";
import { ContentCard } from "../components/ContentCard";
import { Header } from "../components/Header";
import { useEffect } from "react";
import carport from "../components/images/bricklaying/carport.jpeg";
import fassadensanierung from "../components/images/bricklaying/fassadensanierung.jpeg";
import fensterbau from "../components/images/bricklaying/fensterbau.webp";
import treppe from "../components/images/bricklaying/treppe.jpeg";
import verklinkern from "../components/images/bricklaying/verklinkern.webp";
import wanddurchbruch from "../components/images/bricklaying/wanddurchbruch.jpeg";
import { ProjectPlaner } from "../components/ProjectPlaner";

export function Bricklaying({ ...rest }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const services = [
    {
      title: "Wanddurchbrüche und -schließungen",
      picture: wanddurchbruch,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },
    {
      title: "Fenster- und Türeneinbau",
      picture: fensterbau,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },

    {
      title: "Gebäude verklinkern",
      picture: verklinkern,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },
    {
      title: "Carports zumauern",
      picture: carport,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },
    {
      title: "Treppen und kleine Gartenmauern",
      picture: treppe,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },
    {
      title: "Fassadensanierung",
      picture: fassadensanierung,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },
    ,
  ];

  return (
    <Box {...rest}>
      <Header />
      <Stack>
        <Text
          py={10}
          fontWeight={"extrabold"}
          fontSize={[20, 25, 30, 30]}
          textAlign={"center"}
        >
          Wir bieten diverse Dienstleistungen rund um das Maurerhandwerk an
        </Text>
        <Stack spacing={"10vh"} pb={5}>
          {services.map((service, index) => {
            return (
              <ContentCard
                key={index}
                id={index}
                title={service.title}
                picture={service.picture}
                text={service.text}
              />
            );
          })}
        </Stack>
        <ProjectPlaner start={0} pt={5} />
        {/*   <Footer pt={'10vh'} /> */}
      </Stack>
    </Box>
  );
}
