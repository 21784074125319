import {
  Box,
  Center,
  Text,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Stack,
  VStack,
  Spacer,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import "../css/BurgerMenu.css";
import React, { useState } from "react";
import { GiFreemasonry, GiConcreteBag, GiHamburgerMenu } from "react-icons/gi";
import { BsFillFilePersonFill, BsFillGrid3X2GapFill } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { MdContacts } from "react-icons/md";
import { GoLaw } from "react-icons/go";
import { CurrentlyInProgress } from "./CurrentlyInProgress";
import { RiGalleryFill } from "react-icons/ri";
import { mainColor } from "./mainColor";

export function BurgerMenu({ ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [open, setOpen] = useState(false);
  const btnRef = React.useRef();

  const services = [
    {
      name: "Maurerarbeiten",
      iconSmall: <GiFreemasonry />,
      link: "/maurerarbeiten",
    },
    {
      name: "Fliesenarbeiten",
      iconSmall: <BsFillGrid3X2GapFill />,
      link: "/fliesenarbeiten",
    },
    {
      name: "Stahl-Betonbau",
      iconSmall: <GiConcreteBag />,
      link: "/stahl-betonbau",
    },
  ];

  const sites = [
    {
      name: "Start",
      iconSmall: <FaHome fontSize={20} />,
      link: "/start",
    },
    {
      name: "Galerie",
      iconSmall: <RiGalleryFill fontSize={20} />,
      link: "/galerie",
      disabled: true,
    },
    {
      name: "Referenzen",
      iconSmall: <StarIcon fontSize={20} />,
      link: "/bewertungen ",
    },
  ];

  const sitesTwo = [
    {
      name: "Jobs",
      iconSmall: <BsFillFilePersonFill fontSize={20} />,
      link: "/jobs",
    },
    {
      name: "Kontakt",
      iconSmall: <MdContacts fontSize={20} />,
      link: "/kontakt",
    },
    {
      name: "Impressum",
      iconSmall: <GoLaw fontSize={20} />,
      link: "/impressum",
    },
    {
      name: "Datenschutz",
      iconSmall: <GoLaw fontSize={20} />,
      link: "/datenschutz",
    },
  ];

  const {
    isOpen: isOpenProgress,
    onOpen: onOpenProgress,
    onClose: onCloseProgress,
  } = useDisclosure();

  return (
    <>
      <Box className="icon" ref={btnRef} onClick={onOpen} {...rest}>
        <GiHamburgerMenu size={30} />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={"full"}
      >
        <CurrentlyInProgress
          isOpen={isOpenProgress}
          onOpen={onOpenProgress}
          onClose={onCloseProgress}
        />
        <DrawerOverlay />
        <DrawerContent h={window.innerHeight}>
          <DrawerCloseButton _focus={{ boxShadow: "none" }} size={"lg"} />
          <DrawerBody>
            <Stack h={"100%"} justifyContent={"space-around"}>
              <Spacer />
              <VStack spacing={7}>
                {sites.map((site, i) => {
                  return (
                    <Box key={i}>
                      <a href={site.disabled !== true ? site.link : undefined}>
                        <Center
                          color="black"
                          fontSize={25}
                          fontWeight={"extrabold"}
                          cursor={"pointer"}
                          onClick={() => {
                            if (site.disabled) {
                              onOpenProgress();
                            } else {
                              onClose();
                            }
                          }}
                        >
                          <Text>{site.name}</Text>
                        </Center>
                      </a>
                    </Box>
                  );
                })}
                <Center
                  fontSize={25}
                  fontWeight={"extrabold"}
                  onClick={() => setOpen(!open)}
                >
                    <Text
                      display={"inline-block"}
                      textAlign={"center"}
                      fontSize={"25.5px"}
                    >
                      Leistungen
                    </Text>
                </Center>

                {open && (
                  <>
                    {services.map((service, i) => {
                      return (
                        <Center
                          key={i}
                          color={mainColor}
                          fontSize={25}
                          fontWeight={"extrabold"}
                          cursor={"pointer"}
                          onClick={() => {
                            onOpenProgress();
                          }}
                        >
                          <Text> {service.name}</Text>
                        </Center>
                      );
                    })}
                  </>
                )}
                {sitesTwo.map((site, i) => {
                  return (
                    <Box key={i}>
                      <a href={site.disabled !== true && site.link}>
                        <Center
                          color="black"
                          fontSize={25}
                          fontWeight={"extrabold"}
                          cursor={"pointer"}
                          onClick={() => {
                            if (site.disabled) {
                              onOpenProgress();
                            } else {
                              onClose();
                            }
                          }}
                        >
                          <Text>{site.name}</Text>
                        </Center>
                      </a>
                    </Box>
                  );
                })}
              </VStack>
              <Spacer />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
