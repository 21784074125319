import {
  Stack,
  Text,
  Box,
  HStack,
  useBreakpointValue,
  Spacer,
  Image,
} from "@chakra-ui/react";
import { Header } from "../components/Header";
import { mainColor } from "../components/mainColor";
import { MdWebAsset } from "react-icons/md";
import { useEffect } from "react";
import logo from "../components/images/logo/logo_dad.png";
import { mainSpacing } from "../components/mainSpacing";

export function Imprint({ ...rest }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });
  return (
    <Box {...rest}>
      <Header />
      <Stack
        pl={mainSpacing}
        py={[10, 15, 20, 20]}
        fontWeight={"bold"}
        fontSize={20}
      >
        <Box style={{ color: mainColor, textDecoration: "underline" }}>
          <Text color="black" fontSize={35}>
            IMPRESSUM
          </Text>
        </Box>
        <Stack>
          <HStack alignItems={"flex-end"}>
            <Stack spacing={[10, 3, 3, 3]}>
              {variant === "base" && <Box mt={"-10px"} />}
              <Text>
                Kroll GmbH Bauausführungen
                <br />
                Julius-Brecht-Str. 24
                <br />
                24148 Kiel
              </Text>
              {variant !== "base" && <br />}
              <Box>
                <Text
                  fontSize={20}
                  _hover={{ color: mainColor }}
                  cursor={"pointer"}
                  onClick={() => (window.location = "tel:017641947756")}
                >
                  0176 419 477 56
                </Text>
                <Text
                  _hover={{ color: mainColor }}
                  cursor={"pointer"}
                  onClick={() =>
                    (window.location = "mailto:info@kroll-bau.com")
                  }
                >
                  info@kroll-bau.com
                </Text>
              </Box>
              {variant !== "base" && <br />}
              <Text>
                Geschäftsführer:
                <br />
                Wilfried Kroll
              </Text>
              {variant !== "base" && <br />}
              <Text>Amtsgericht Kiel - HRB 3988 </Text>
            </Stack>
            {(variant === "md" || variant === "lg") && (
              <>
                <Spacer />
                <Image src={logo} maxW={"45vw"} />
              </>
            )}
          </HStack>
          <a href={"/datenschutz"}>
            <Text as="u" cursor="pointer">
              Datenschutzerklärung
            </Text>
          </a>
          <a href={"/zuschreibungen"} rel={"nofollow"}>
            <Text as="u" cursor="pointer">
              Credits
            </Text>
          </a>
        </Stack>
        <br />
        <Box style={{ color: mainColor, textDecoration: "underline" }}>
          <Text color="black" fontSize={[25, 25, 30, 35]}>
            HAFTUNGSHINWEIS
          </Text>
        </Box>
        <br />
        <Text>
          Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
          für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten
          sind ausschließlich deren Betreiber verantwortlich.
        </Text>
        <br />
        <br />
        <Box style={{ color: mainColor, textDecoration: "underline" }}>
          <Text color="black" fontSize={[25, 25, 30, 35]}>
            GESTALTUNG, KONZEPTION, TECHNISCHE UMSETZUNG
          </Text>
        </Box>
        <br />
        <Text>
          Lukas Körber WebWerk am Meer <br />
          Webentwicklung- und Webdesign
          <br />
          <br />
          <HStack
            as={"a"}
            href={"https://webwerk-am-meer.de"}
            target={"_blank"}
          >
            <MdWebAsset size={40} />
            <p>www.webwerk-am-meer.de</p>
          </HStack>
        </Text>
      </Stack>
    </Box>
  );
}
