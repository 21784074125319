import {
  Box,
  Stack,
  Text,
  HStack,
  Wrap,
  useBreakpointValue,
} from "@chakra-ui/react";
import { mainColor } from "./mainColor";
import { jobs } from "../data/Jobs";

export function CurrentJobs({ ...rest }) {
  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  const JobCard = ({ job, i, ...rest }) => {
    return (
      <Box
        fontWeight={"bold"}
        px={10}
        py={8}
        w={400}
        maxW={"95vw"}
        borderWidth={2}
        borderColor={mainColor}
        {...rest}
      >
        <Text
          h={variant !== "base" && 70}
          textAlign={"center"}
          color={mainColor}
          fontSize={[20, 20, 20, 20, 25]}
          fontWeight={"black"}
        >
          {job.name}
        </Text>
        <Box h={variant !== "base" && 300}>
          <Text py={3} fontSize={18}>
            {" "}
            QUALLIFIKATIONEN{" "}
          </Text>
          {job.qualifications.map((qualification, i) => {
            return (
              <HStack align={"flex-start"} key={i}>
                <Text>-</Text>
                <Text>{qualification}</Text>
              </HStack>
            );
          })}
        </Box>
        <Box>
          <Text pt={[8, 3, 3, 0]} pb={3} fontSize={18}>
            {" "}
            VORTEILE{" "}
          </Text>
          {job.benefits.map((benefit, i) => {
            return (
              <HStack align={"flex-start"} key={i}>
                <Text>-</Text>
                <Text>{benefit}</Text>
              </HStack>
            );
          })}
        </Box>
      </Box>
    );
  };

  return (
    <Stack {...rest}>
      <Text textAlign={"center"} fontWeight={"bold"} fontSize={32}>
        Offene Stellen
      </Text>
      <Wrap justify="center" spacing={"50px"} pt={5}>
        {variant !== "lg" ? (
          <>
            {jobs.map((job, i) => {
              return <JobCard key={i} job={job} i={i} />;
            })}
          </>
        ) : (
          <HStack
            w={"100%"}
            align={"flex-start"}
            justifyContent={"space-between"}
          >
            {jobs.map((job, i) => {
              return <JobCard h={"100%"} w={"100%"} job={job} i={i} key={i} />;
            })}
          </HStack>
        )}
      </Wrap>
    </Stack>
  );
}
