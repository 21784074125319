import {
  Box,
  Center,
  Text,
  Stack,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Header } from "../components/Header";
import { MainPageContentBlock } from "../components/MainPageContentBlock";
import maurer_neu from "../components/images/bricklaying_new.png";
import maurer_neu2 from "../components/images/mauer2.jpg";
import boden_neu from "../components/images/tilelayer_new.png";
import beton_neu from "../components/images/steel_new.png";
import { useEffect, useRef, useState } from "react";

export const MotivationText = (props) => {
  const { content, ...rest } = props;

  return (
    <Box
      style={{ wordSpacing: "0px" }}
      px={[1, 1, 12, 12]}
      fontSize={[15, 20, 20, 22]}
      textAlign={"center"}
      fontWeight={["extrabold", "black", "black", "black"]}
      letterSpacing={1.2}
      wordBreak={"10px"}
    >
      {typeof content === "object" ? content : <Text {...rest}>{content}</Text>}
    </Box>
  );
};

export function Main({ ...rest }) {
  const variant = useBreakpointValue({ base: "base", sm: "sm", md: "md" });

  const imageRef = useRef(null);
  const textRef = useRef(null);
  const [barHeight, setBarHeight] = useState(0);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    setBarHeight(
      (textRef.current.offsetWidth + imageRef.current.offsetWidth) / 10
    );
  }, [dimensions]);

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <Box minH={"100vh"} {...rest}>
      <Header />
      <Stack h={"100%"} spacing={7}>
        <Box w={20} />
        <MotivationText
          content={
            <Text>
              {" "}
              Unsere Leistungen beginnen mit einer Fachkompetenten Beratung,
              über die {variant === "base" && <br />} Planung bis hin zur
              Ausführung.
            </Text>
          }
        />
        <Stack spacing={[5, 5, "37px", "37px"]}>
          <MainPageContentBlock
            title={"MAURERARBEITEN"}
            link={"/maurerarbeiten"}
            height={barHeight}
            image={variant === "base" ? maurer_neu2 : maurer_neu}
            size={900}
          />
          <MainPageContentBlock
            textID={textRef}
            imageID={imageRef}
            title={"FLIESENARBEITEN"}
            link={"/fliesenarbeiten"}
            image={boden_neu}
            height={barHeight}
            size={900}
            side={"right"}
          />
          <MainPageContentBlock
            title={"STAHL-BETONBAU"}
            link={"/stahl-betonbau"}
            image={beton_neu}
            height={barHeight}
            size={900}
          />
        </Stack>
        <MotivationText content="Wir arbeiten gewerkeübergreifend mit etablierten Fachbetrieben aus der Region." />
        <Box pt={"45px"}>
          <HStack spacing={0}>
            <a
              rel="nofollow"
              target="_blank"
              href="https://www.holstein-kiel.de/"
            >
              <Box
                h={"45px"}
                w={"100%"}
                bgColor={"black"}
                color="white"
                cursor={"pointer"}
                position="absolute"
                bottom={0}
              >
                <Center h={"100%"}>
                  <Text
                    textAlign={"center"}
                    fontWeight={"black"}
                    w={"100%"}
                    color="white"
                    fontSize={[15, 14, 16, 20]}
                  >
                    KIELER S.V. HOLSTEIN VON 1900
                  </Text>
                </Center>
              </Box>
            </a>
          </HStack>
        </Box>
      </Stack>
    </Box>
  );
}
