import { Stack, Text, Box } from "@chakra-ui/react";
import { ContentCard } from "../components/ContentCard";
import { Header } from "../components/Header";
import { useEffect } from "react";
import bad from "../components/images/tileWork/bad.webp";
import küche from "../components/images/tileWork/küche.jpeg";
import mosaik from "../components/images/tileWork/mosaik.jpeg";
import silikon from "../components/images/tileWork/silikon.jpeg";
import wohnräume from "../components/images/tileWork/wohnräume.jpeg";
import { ProjectPlaner } from "../components/ProjectPlaner";

export function TileWork({ ...rest }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const services = [
    {
      title: "Bad Fliesen",
      picture: bad,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },
    {
      title: "Küchen Fliesen",
      picture: küche,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },

    {
      title: "Boden Fliesen",
      picture: wohnräume,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },
    {
      title: "Mosaik Fliesen",
      picture: mosaik,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },
    {
      title: "Erneuerung von Silikon Fugen",
      picture: silikon,
      text: (
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.{" "}
          <strong>
            At vero eos et accusam et justo duo dolores et ea rebum.
          </strong>{" "}
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren,{" "}
          <strong>
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </strong>
        </Text>
      ),
    },

    ,
  ];

  return (
    <Box {...rest}>
      <Header />
      <Stack>
        <Text
          py={10}
          fontWeight={"extrabold"}
          fontSize={[20, 25, 30, 30]}
          textAlign={"center"}
        >
          Wir bieten diverse Dienstleistungen rund um Fliesenarbeiten an
        </Text>
        <Stack spacing={"10vh"} pb={5}>
          {services.map((service, index) => {
            return (
              <ContentCard
                key={index}
                id={index}
                title={service.title}
                picture={service.picture}
                text={service.text}
              />
            );
          })}
        </Stack>
        <ProjectPlaner start={0} pt={5} />
      </Stack>
    </Box>
  );
}
