import React, { useState } from "react";
import { Main } from "./routes/Main";
import { Routes, Route } from "react-router-dom";
import { Contact } from "./routes/Contact";
import { Imprint } from "./routes/Imprint";
import { Bricklaying } from "./routes/Bricklaying";
import { TileWork } from "./routes/TileWork";
import { Concrete } from "./routes/Concrete";
import { Reference } from "./routes/Reference";
import { AppContext } from "../src/AppContext";
import { PlanerContext } from "../src/PlanerContext";
import { Review } from "./routes/Review";
import { Blog } from "./routes/Blog";
import { BlogArticle } from "./components/blog/BlogArticel";
import { CbdForAnimals } from "./components/blog/articles/CbdForAnimals";
import { articles } from "./components/blog/BlogContent";
import { Reviews } from "./routes/Reviews";
import { Datasecruity } from "./routes/Datasecruity";
import { Attributions } from "./routes/Attributions";
import { ProjectPlanerPage } from "./routes/ProjectPlanerPage";
import { Footer } from "./components/Footer";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { Jobs } from "./routes/Jobs";
import "./components/main.css";

function App() {
  const [reference, setReference] = useState({
    title: "Beton,- und Betonsanierungsarbeiten",
    images: undefined,
  });

  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  const [newAnwsers, setAnwsers] = useState([
    [
      {
        anwser: "",
        next: 1,
        kind: "",
        icon: "",
      },
    ],
    [
      {
        anwser: "",
        next: 1,
        kind: "",
        icon: "",
      },
    ],
    [
      {
        anwser: "",
        next: 1,
        kind: "",
        icon: "",
      },
    ],
  ]);

  return (
    <AppContext.Provider value={[reference, setReference]}>
      <PlanerContext.Provider value={[newAnwsers, setAnwsers]}>
        <Box>
          <Routes>
            <Route path="*" element={<Main />} />
            <Route path="/" element={<Main />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/impressum" element={<Imprint />} />
            <Route path="/maurerarbeiten" element={<Bricklaying />} />
            <Route path="/fliesenarbeiten" element={<TileWork />} />
            <Route path="/stahl-betonbau" element={<Concrete />} />
            <Route path="/bewertung" element={<Review />} />
            <Route path="/bewertungen" element={<Reviews />} />
            <Route path="/datenschutz" element={<Datasecruity />} />
            <Route path="/projektplaner" element={<ProjectPlanerPage />} />
            <Route path="/zuschreibungen" element={<Attributions />} />
            <Route
              path="/referenzen"
              element={
                <Reference title={reference.title} images={reference.images} />
              }
            />
            <Route path="/blog" element={<Blog />} />
            <Route
              path={articles[0].link}
              element={
                <BlogArticle article={articles[0]}>
                  <CbdForAnimals />
                </BlogArticle>
              }
            />
          </Routes>
          {variant !== "base" && variant !== "sm" && <Footer />}
        </Box>
      </PlanerContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
