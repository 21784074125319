import { Box, Center, Stack, HStack, Text, Image } from "@chakra-ui/react";
import { BlogSideBar } from "../BlogSideBar";
import { ViewIcon, ChatIcon, TimeIcon } from "@chakra-ui/icons";
import "./RenderBlogArticle.css";

export function DesktopBlogArticle(props) {
  const { children, article } = props;

  return (
    <Box pb={20}>
      <HStack spacing={0} alignItems={"flex-start"} pt={10}>
        {/* <Box w={'25vw'} h={200}></Box> */}
        <Center w={"100vw"}>
          <Stack w={"70vw"}>
            <Image
              src={article.pictureLink}
              alt={article.pictureAlt}
              borderRadius={10}
            />
            <Text fontSize={28} as="u" textAlign="center" fontWeight="bold">
              {" "}
              {article.header}{" "}
            </Text>
            <Center>
              <HStack spacing={7} fontSize={18} pb={5}>
                <HStack>
                  <TimeIcon />
                  <Text color="gray.500">20.04.2022</Text>
                </HStack>
                <HStack>
                  <ChatIcon />
                  <Text color="gray.500">
                    {article.comments.length} Kommentare{" "}
                  </Text>
                </HStack>
                <HStack>
                  <ViewIcon />
                  <Text color="gray.500">{article.viewCount}</Text>
                </HStack>
              </HStack>
            </Center>
            <Box>{children}</Box>
          </Stack>
        </Center>
        {/*         <Box w={'25vw'}>
          <Center>
            <Stack>
              <BlogSideBar w={'100%'} />
            </Stack>
          </Center>
        </Box> */}
      </HStack>
    </Box>
  );
}
