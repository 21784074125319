import { Box, Text } from "@chakra-ui/react";

export function CbdForAnimals(props) {
  return (
    <Box>
      {/*       <Text fontWeight="extrabold" fontSize={28} textAlign="center">
        CBD für Tiere
      </Text> */}
      <Text textAlign="left">
        <strong>
          {" "}
          Sowie bei Menschen reagieren Tiere auch mit dem Endocannabinoid-system
          auf CBD, dabei spielt es keine Rolle was für ein Tier du hast!
        </strong>{" "}
        <br />
        <br />
        Viele neue Forschungen zeigen, dass die{" "}
        <strong>
          Verwendung von CBD bei Tieren sowohl als auch bei Menschen
          gesundheitlich fördernd ist und Beschwerden lindern kann.
        </strong>
        <br />
        <br />
        CBD wird daher als <strong>Stress und Angst lösend</strong> beschrieben.
        Ein weiterer positiver Effekt ist die{" "}
        <strong>
          Steigerung der Lebensqualität, vor allem bei Tieren mit chronischen
          Schmerzen
        </strong>
        , da CBD auch als schmerzlindernd und krampflösend beschrieben wird. Wie
        Sie sehen sollten sie sich Gedanken darüber machen, ob CBD eine gute
        Möglichkeit, für ihr Tier ist. <br />
        <br /> Am meisten wird CBD an Tiere in Ölform verabreicht, da auch dort
        die Dosierung leicht zu „berechnen“ ist. Natürlich wirkt CBD wie bei
        Menschen auf jedes Tier in verschiedener Stärke und Art, trotzdem gibt
        es ein paar einfache Methoden, um die genaue Dosierung für ihr Tier
        festzustellen.{" "}
        <strong>
          Zuerst sollten sie das Gewicht ihres Tieres beachten, empfohlen wird
          zunächst der Start mit einem Tropfen am Tage, bis hin zu 2–3 Tropfen
          am Tage um die gewünschten Erfolge zu verspüren.
        </strong>{" "}
        Dies ist meist auch richtig, doch nach Erfahrungsberichten ist dies der
        Fall bei Hunden, Katzen und oder noch kleineren und leichteren Tieren.{" "}
        <br />
        <br />
        Sollten Sie jedoch CBD bei zum Beispiel Ihrem Pferd anwenden, sollte es
        in der Regel kein Problem sein, mit 3 bis zu 5 Tropfen am Tag zu
        starten. Genau berechnen kann man die Dosierung anhand des Gewichtes
        nicht, da es immer noch unterschiedlich von Person zu Person und Tier zu
        Tier wirkt. <br /> <br />{" "}
        <strong>
          Allgemein wird jedoch empfohlen, bei einer geringen Wirkungskraft 2 mg
          CBD pro 10 Kilo, bei einer mittelgroßen Wirkungskraft 7 mg CBD pro 10
          Kilo und bei einer großen Wirkungskraft 13 mg CBD pro 10 Kilo Gewicht
          zu nehmen.{" "}
        </strong>
        <br /> <br />
        Ich werde dies in einem Beispiel vereinfachen.{" "}
        <strong>
          Am Ende des Artikels stellen wir aber auch einen Dosierungs-Rechner
          bereit.
        </strong>{" "}
        Nimmt man ein Hund mit einem Gewicht von 20KG und einem CBD-Öl mit 10 %
        CBD, so enthält dieses pro 10ml 1000 mg CBD, benötigt man nun eine Dosis
        von ca. 30 mg, so entspricht dies 0.3ml des 10 % CBD Öls. Für 1 ml Öl
        wird in etwa 20–25 Tropfen angegeben, sodass man etwa 8 Tropfen am Tag
        pro Dosis bräuchte. Bei Fragen zu der richtigen Nutzung von CBD, oder
        Anregungen zu unserem Blog Artikel meldet euch gerne bei
        Kontakt@Charlys-CBD-Welt.com.
      </Text>{" "}
    </Box>
  );
}
