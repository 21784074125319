import {
  Box,
  Center,
  Text,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Stack,
  HStack,
  Image,
  Spacer,
} from "@chakra-ui/react";
import { BiFilterAlt } from "react-icons/bi";
import { PhoneIcon } from "@chakra-ui/icons";
import "../../css/BurgerMenu.css";
import React from "react";
import { FaHome } from "react-icons/fa";
import { MdContacts } from "react-icons/md";
import { GoLaw } from "react-icons/go";
import { GiBrickPile } from "react-icons/gi";
import { HiSpeakerphone } from "react-icons/hi";
import logo from "../images/logo/logo.png";
import { categories } from "./BlogContent";
import { FiFacebook } from "react-icons/fi";
import { FaWhatsapp, FaTelegramPlane, FaTwitter } from "react-icons/fa";

export function BlogFilterIcon(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { iconSize = 24, iconBoxSize = 45, setFilter, ...rest } = props;

  const linkText = "Hier, guck was ich gefunden habe.";

  const socialMedia = [
    {
      name: "Facebook",
      iconSmall: <FiFacebook color="black" fontSize={iconSize} />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
    },
    {
      name: "WhatsApp",
      iconSmall: <FaWhatsapp color="black" fontSize={iconSize} />,
      link: `https://api.whatsapp.com/send?text=${linkText}%0a${window.location.href}`,
    },
    {
      name: "Telegram",
      iconSmall: <FaTelegramPlane color="black" fontSize={iconSize} />,
      link: `https://t.me/share/url?url=${window.location.href}&text=${linkText}`,
    },
    {
      name: "Twitter",
      iconSmall: <FaTwitter color="black" fontSize={iconSize} />,
      link: `https://twitter.com/intent/tweet?text=${window.location.href}`,
    },
  ];

  const SocialIcon = (props) => {
    const { link, icon, name, index } = props;
    return (
      <HStack>
        <Box
          color="black"
          borderRadius={"50%"}
          borderColor={"black"}
          borderWidth={1}
          h={iconBoxSize}
          w={iconBoxSize}
          key={index}
          cursor="pointer"
          onClick={() => (window.location = link)}
        >
          <Center color="black" h={"100%"} w={"100%"}>
            {icon}
          </Center>
        </Box>
        {/*         <Text fontWeight={'bold'} fontSize={17}>
          {' '}
          Teile es auf {name}
        </Text> */}
      </HStack>
    );
  };

  const sites = [
    {
      name: "Start",
      iconSmall: <FaHome />,
      link: "/start",
    },
    {
      name: "Kontakt",
      iconSmall: <MdContacts />,
      link: "/kontakt",
    },
    {
      name: "Impressum",
      iconSmall: <GoLaw />,
      link: "/impressum",
    },
  ];

  return (
    <>
      <HStack mx={3}>
        <Spacer />
        <HStack ref={btnRef} onClick={onOpen}>
          <Box borderColor="black" {...rest}>
            <Center h={"100%"}>
              <BiFilterAlt size={40} />
            </Center>
          </Box>
          <Text fontWeight={"bold"} fontSize={20}>
            {" "}
            Filter{" "}
          </Text>
        </HStack>
      </HStack>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Stack spacing={5}>
              <Image src={logo} pt={5} />
              <Stack spacing={4}>
                <Center>
                  <HStack fontSize={25}>
                    <GiBrickPile />
                    <Text fontWeight={"extrabold"}> Themenbereiche </Text>
                  </HStack>
                </Center>
                <Stack>
                  <Text
                    textAlign="center"
                    cursor={"pointer"}
                    fontWeight={"bold"}
                    onClick={() => {
                      setFilter("");
                      onClose();
                    }}
                    fontSize={18}
                    as="u"
                  >
                    {"Alle"}
                  </Text>
                  {categories().map((c, i) => {
                    return (
                      <Text
                        textAlign={"center"}
                        onClick={() => {
                          setFilter(c);
                          onClose();
                        }}
                        key={i}
                        as="u"
                        fontWeight={"bold"}
                      >
                        {c}
                      </Text>
                    );
                  })}
                </Stack>
                <Spacer />
                <Center>
                  <HStack fontSize={25}>
                    <HiSpeakerphone />
                    <Text fontWeight={"extrabold"}> Verbreiten </Text>
                  </HStack>
                </Center>
                <Center>
                  <HStack>
                    {socialMedia.map((icon, i) => {
                      return (
                        <SocialIcon
                          key={i}
                          icon={icon.iconSmall}
                          link={icon.link}
                          name={icon.name}
                          index={i}
                        />
                      );
                    })}
                  </HStack>
                </Center>
              </Stack>

              <Spacer />
              <Center>
                <Stack
                  fontWeight={"bold"}
                  fontSize={17}
                  spacing={1}
                  textAlign={"center"}
                >
                  <Text>INFO@KROLL-BAU.COM</Text>
                  <Center>
                    <HStack>
                      <PhoneIcon />
                      <Text
                        cursor="pointer"
                        onClick={() => (window.location = "tel:017641947756")}
                      >
                        0176 419 477 56
                      </Text>
                    </HStack>
                  </Center>

                  <Center>
                    <HStack>
                      <PhoneIcon />
                      <Text
                        cursor="pointer"
                        onClick={() => (window.location = "tel:043434390470")}
                      >
                        04343 439 047 0
                      </Text>
                    </HStack>
                  </Center>
                </Stack>
              </Center>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
