import {
  Stack,
  HStack,
  Input,
  Button,
  Text,
  Textarea,
  Box,
  Spacer,
  Checkbox,
  useBreakpointValue,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Header } from "../components/Header";
import { mainColor } from "../components/mainColor";
import { useToastHook } from "../components/useToastHook";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import axios from "axios";
import { mainSpacing } from "../components/mainSpacing";
import { MobileNumberShowcase } from "../components/contact/MobileNumberShowcase";
import { TelephoneNumberShowcase } from "../components/contact/TelephoneNumberShowcase";
import { EmailShowcase } from "../components/contact/EmailShowcase";

export function Contact({ ...rest }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [, newToast] = useToastHook();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  const Datasecruity = (props) => {
    return (
      <Stack>
        <HStack w={"100%"} mt={3}>
          <Checkbox
            size={"lg"}
            colorScheme="red"
            {...register("datasecruity", { required: true })}
          />
          <Text fontSize={12} textAlign="left">
            Ich habe die
            <a href="/datenschutz"> Datenschutzerklärung</a> gelesen und stimme
            dieser zu.
          </Text>
        </HStack>
        {errors.datasecruity && (
          <Text color={mainColor} textAlign={"center"} fontSize={10}>
            {" "}
            Bitte stimmen Sie unseren Nutzungsbedingungen und der
            Datenschutzerklärung zu
          </Text>
        )}
      </Stack>
    );
  };

  const onSubmit = async (data) => {
    const { name, email, message, tel } = data;
    try {
      const response = await axios.post(
        `https://kroll-bau.com/php/contactFormular.php?name=${name}&email=${email}&text=${message}&tel=${tel}`
      );
      console.log(response.data); 
      if(response.data === "Message sent successfully..."){
        reset();
        newToast({
          title: "Danke für ihre Anfrage",
          message:
            "Ihre Anfrage wurde versendet, wir melden uns so schnell wie möglich bei Ihnen",
          status: "success",
        });
      }else{
        newToast({
          title: "Ein Fehler ist aufgetreten",
          message:
            "Ihre Anfrage konnte nicht gesendet werden. Bitte versuchen Sie es erneut oder kontaktieren Sie uns telefonisch.",
          status: "error",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const middleHeight = 332;

  return (
    <Box minH={"100vh"} h={"100%"} {...rest}>
      <Header className="headerClass" minH={"100vh"}>
        <Spacer />
        <Stack
          className="contentClass"
          px={mainSpacing}
          pt={[10, 10, 10, 0]}
          mb={5}
          h={variant === "lg" && 95}
        >
          <Text
            fontWeight={"extrabold"}
            fontSize={[24, 30, 30, 30]}
            color={"black"}
            mt={"-10px"}
          >
            KONTAKTIEREN SIE UNS
          </Text>
          {variant !== "base" && (
            <Text fontWeight={"black"} fontSize={45}>
              WIR FREUEN UNS AUF IHR PROJEKT
            </Text>
          )}
        </Stack>
        {variant === "lg" && <Spacer />}
        <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
          {variant === "lg" ? (
            <HStack
              h={middleHeight}
              alignItems={"flex-start"}
              px={[1, 1, 12, 12]}
            >
              <Stack w={"30vw"} h={middleHeight}>
                <TelephoneNumberShowcase />
                <Spacer />
                <MobileNumberShowcase />
                <Spacer />
                <EmailShowcase />
              </Stack>
              <Spacer />
              <Stack w={"30vw"} h={middleHeight}>
                <Textarea
                  borderRadius={0}
                  h={"100%"}
                  placeholder={"Wie können wir Ihnen helfen?"}
                  {...register("message", {
                    required: true,
                  })}
                  _focus={{
                    borderColor: "black",
                    boxShadow: `0px 0px 0px 1px black`,
                  }}
                />
                <Button
                  bgColor={mainColor}
                  _hover={{ bgColor: mainColor }}
                  color="white"
                  type="submit"
                  borderRadius={0}
                >
                  SENDEN
                </Button>
              </Stack>
              <Spacer />
              <Stack w={"30vw"} h={200}>
                <Input
                  borderRadius={0}
                  _focus={{
                    borderColor: mainColor,
                    boxShadow: `0px 0px 0px 1px ${mainColor}`,
                  }}
                  placeholder={"Ihr Name *"}
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Bitte geben Sie ihren Namen ein",
                    },
                    maxLength: {
                      value: 30,
                      message: "Bitte geben Sie 30 Zeichen, oder weniger ein",
                    },
                  })}
                />
                {errors.name && (
                  <Text color={mainColor}> Bitte gebe einen Namen ein</Text>
                )}
                <Spacer />
                <Input
                  _focus={{
                    borderColor: mainColor,
                    boxShadow: `0px 0px 0px 1px ${mainColor}`,
                  }}
                  borderRadius={0}
                  placeholder={"Ihre Emailadresse *"}
                  {...register("email", {
                    required: true,
                  })}
                />
                {errors.email && (
                  <Text color={mainColor}>
                    {" "}
                    Bitte gebe eine Emailadresse ein
                  </Text>
                )}
                <Spacer />
                <Input
                  _focus={{
                    borderColor: mainColor,
                    boxShadow: `0px 0px 0px 1px ${mainColor}`,
                  }}
                  borderRadius={0}
                  placeholder={"Ihre Telefonnummer *"}
                  {...register("tel", {
                    required: true,
                  })}
                />
                {errors.tel && (
                  <Text color={mainColor}>
                    {" "}
                    Bitte gebe eine Telefonnummer ein
                  </Text>
                )}
                <Datasecruity />
              </Stack>
            </HStack>
          ) : (
            <Stack spacing={10} maxWidth={600} py={10} px={mainSpacing}>
              <Wrap>
                <WrapItem>
                  <TelephoneNumberShowcase />
                </WrapItem>
                <WrapItem>
                  <MobileNumberShowcase />
                </WrapItem>
                <WrapItem>
                  <EmailShowcase />
                </WrapItem>
              </Wrap>
              <Textarea
                w={"100%"}
                borderRadius={0}
                h={[200, 225, 250, 350]}
                placeholder={"Wie können wir Ihnen helfen?"}
                _focus={{
                  borderColor: "black",
                  boxShadow: `0px 0px 0px 1px black`,
                }}
                {...register("message", {
                  required: true,
                })}
              />
              <Stack h={200}>
                <Input
                  maxW={"95vw"}
                  borderRadius={0}
                  placeholder={"Ihr Name *"}
                  _focus={{
                    borderColor: mainColor,
                    boxShadow: `0px 0px 0px 1px ${mainColor}`,
                  }}
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Bitte geben Sie ihren Namen ein",
                    },
                    maxLength: {
                      value: 30,
                      message: "Bitte geben Sie 30 Zeichen, oder weniger ein",
                    },
                  })}
                />
                {errors.name && (
                  <Text color={mainColor}> Bitte gebe einen Namen ein</Text>
                )}
                <Spacer />
                <Input
                  maxW={"95vw"}
                  borderRadius={0}
                  placeholder={"Ihre Emailadresse *"}
                  _focus={{
                    borderColor: mainColor,
                    boxShadow: `0px 0px 0px 1px ${mainColor}`,
                  }}
                  {...register("email", {
                    required: true,
                  })}
                />
                {errors.email && (
                  <Text color={mainColor}>
                    {" "}
                    Bitte gebe eine Emailadresse ein
                  </Text>
                )}
                <Spacer />
                <Input
                  maxW={"95vw"}
                  borderRadius={0}
                  placeholder={"Ihre Telefonnummer *"}
                  _focus={{
                    borderColor: mainColor,
                    boxShadow: `0px 0px 0px 1px ${mainColor}`,
                  }}
                  {...register("tel", {
                    required: true,
                  })}
                />
                {errors.tel && (
                  <Text color={mainColor}>
                    {" "}
                    Bitte gebe eine Telefonnummer ein
                  </Text>
                )}
                <Datasecruity />
              </Stack>
              <Button
                borderRadius={0}
                bgColor={mainColor}
                _hover={{ bgColor: mainColor }}
                color="white"
                type="submit"
              >
                SENDEN
              </Button>
            </Stack>
          )}
        </form>
        <Spacer />
        <Box h={8} />
      </Header>
    </Box>
  );
}
