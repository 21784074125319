import { Stack, Box } from "@chakra-ui/react";
import { Header } from "../components/Header";

export function Attributions() {
  const Link = (children) => {
    return <Box> {children} </Box>;
  };

  const links = [
    <a
      rel="nofollow"
      href="https://www.flaticon.com/free-icons/terrace"
      title="terrace icons"
    >
      Terrace icons created by Iconic Panda - Flaticon
    </a>,
    <a
      rel="nofollow"
      href="https://www.flaticon.com/free-icons/construction-and-tools"
      title="construction and tools icons"
    >
      Construction and tools icons created by Freepik - Flaticon
    </a>,

    <a
      rel="nofollow"
      href="https://www.flaticon.com/free-icons/business-and-finance"
      title="business and finance icons"
    >
      Business and finance icons created by zafdesign - Flaticon
    </a>,

    <a
      rel="nofollow"
      href="https://www.flaticon.com/free-icons/floor"
      title="floor icons"
    >
      Floor icons created by Smashicons - Flaticon
    </a>,

    <a
      rel="nofollow"
      href="https://www.flaticon.com/free-icons/texture"
      title="texture icons"
    >
      Texture icons created by Smashicons - Flaticon
    </a>,
    <a
      rel="nofollow"
      href="https://www.flaticon.com/free-icons/concrete"
      title="concrete icons"
    >
      Concrete icons created by smashingstocks - Flaticon
    </a>,
    <a
      rel="nofollow"
      href="https://www.flaticon.com/free-icons/materials"
      title="materials icons"
    >
      Materials icons created by orvipixel - Flaticon
    </a>,
    <a
      rel="nofollow"
      href="https://www.flaticon.com/free-icons/floor"
      title="floor icons"
    >
      Floor icons created by Freepik - Flaticon
    </a>,
    <a
      rel="nofollow"
      href="https://www.flaticon.com/free-icons/mosaic"
      title="mosaic icons"
    >
      Mosaic icons created by Freepik - Flaticon
    </a>,
    <a
      rel="nofollow"
      href="https://www.flaticon.com/free-icons/modernization"
      title="modernization icons"
    >
      Modernization icons created by Vectors Tank - Flaticon
    </a>,
    <a
      rel="nofollow"
      href="https://www.flaticon.com/free-icons/renovation"
      title="renovation icons"
    >
      Renovation icons created by Eucalyp - Flaticon
    </a>,
  ];

  return (
    <Box minH={"100vh"}>
      <Header />
      <Stack px={10} pt={10}>
        {links.map((link, index) => {
          return <Box key={index}>{link}</Box>;
        })}
      </Stack>
    </Box>
  );
}
