import { Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { mainColor } from "../components/mainColor";
import { Header } from "../components/Header.js";
import { CurrentJobs } from "../components/CurrentJobs";
import { ApplicationTool } from "../components/ApplicationTool";
import { mainSpacing } from "../components/mainSpacing";

export function Jobs({ ...rest }) {
  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  return (
    <Stack pb={10} {...rest}>
      <Header />
      <Text
        px={mainSpacing}
        pt={8}
        pb={4}
        fontWeight={"bold"}
        textAlign={"center"}
        fontSize={[32, 32, 32, 38]}
        color={mainColor}
      >
        MITARBEITER GESUCHT
      </Text>
      <Text
        letterSpacing={"0.5px"}
        px={mainSpacing}
        textAlign={"center"}
        fontSize={[17, 20, 20, 22]}
        fontWeight={"bold"}
      >
        Unser familiengeführter Meisterbetrieb kümmert sich seit mehr als 30
        Jahren um die Planung {variant === "lg" && <br />}
        und Ausführung von Maurer-, Fliesen- und Stahl-Betonbauarbeiten. Die
        Modernisierung und Sanierung {variant === "lg" && <br />} im Altbau
        sowie An- und Umbauten im Bestand, für Privatkunden und{" "}
        {variant === "lg" && <br />} Wohnungsbaugenossenschaften sind unser
        Tagesgeschäfft.
      </Text>
      <CurrentJobs px={mainSpacing} pt={7} />
      <ApplicationTool px={mainSpacing} pt={10} />
    </Stack>
  );
}
