import {
  Center,
  useBreakpointValue,
  HStack,
  Stack,
  Text,
  Checkbox,
  Box,
  Spacer,
  Textarea,
  Input,
  Button,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { Header } from "../components/Header";
import axios from "axios";
import { StarIcon } from "@chakra-ui/icons";
import { mainColor } from "../components/mainColor";
import { useForm } from "react-hook-form";
import { useToastHook } from "../components/useToastHook";

const StarRating = (props) => {
  const { rating, name, register } = props;
  return (
    <HStack>
      <Radio
        {...register("rating", {
          required: true,
        })}
        value={rating}
        colorScheme="red"
      />{" "}
      <Text> {name}</Text>
      <Spacer />
      {Array(5)
        .fill("")
        .map((_, i) => (
          <StarIcon key={i} color={i < rating ? mainColor : "gray.300"} />
        ))}
    </HStack>
  );
};

export function Review({ ...rest }) {
  const stars = [
    { name: "sehr gut", rating: "5" },
    { name: "gut", rating: "4" },
    { name: "befriedigend", rating: "3" },
    { name: "ausreichend", rating: "2" },
    { name: "mangelhaft", rating: "1" },
  ];

  const onSubmit = async (data) => {
    const { rating, name, email, tel, review } = data;
    try {
      axios.post(
        `https://kroll-bauausfuehrungen.de/php/reviewFormular.php?name=${encodeURI(
          name
        )}&email=${encodeURI(email)}&text=${encodeURI(
          review
        )}&rating=${encodeURI(rating)}`
      );
      reset();
      newToast({
        title: "Danke für ihre Bewertung",
        message: "Ihre Bewertung wird in innerhalb von 24h freigeschaltet.",
        status: "success",
      });
    } catch (e) {
      console.log(e);
    }
  };

  const [, newToast] = useToastHook();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  const Datasecruity = (props) => {
    return (
      <Center>
        <Stack>
          <Center>
            <HStack w={"100%"} pt={3}>
              <Checkbox
                borderRadius={0}
                size={"lg"}
                {...register("datasecruity", { required: true })}
              />
              <Text fontSize={12} textAlign="left">
                Ich habe die
                <a href="/datenschutz"> Datenschutzerklärung</a> gelesen und
                stimme dieser zu.
              </Text>
            </HStack>
          </Center>
          {errors.datasecruity && (
            <Text color={mainColor} textAlign={"center"} fontSize={10}>
              {" "}
              Bitte stimmen Sie unseren Nutzungsbedingungen und der
              Datenschutzerklärung zu
            </Text>
          )}
        </Stack>
      </Center>
    );
  };

  const Inputs = (props) => {
    const { spacers, buttonPadding, ...rest } = props;
    return (
      <Stack h={elementHeight} spacing={0} {...rest}>
        <Input
          focusBorderColor={"#cc0201"}
          borderRadius={0}
          placeholder="Ihr Name *"
          {...register("name", {
            required: true,
          })}
        />
        {spacers && <Spacer />}

        <Input
          focusBorderColor={"#cc0201"}
          borderRadius={0}
          placeholder="Emailadresse *"
          {...register("email", {
            required: true,
          })}
        />
        {spacers && <Spacer />}
        {variant !== "lg" && (
          <Stack pt={buttonPadding}>
            <Button
              borderRadius={0}
              color="white"
              bgColor={mainColor}
              fontWeight={"extrabold"}
              fontSize={20}
              _hover={{ bgColor: mainColor }}
              type="submit"
            >
              {" "}
              Senden{" "}
            </Button>
            <Datasecruity />
          </Stack>
        )}
        {variant === "lg" && (
          <Stack>
            <Button
              color="white"
              borderRadius={0}
              bgColor={mainColor}
              fontWeight={"extrabold"}
              fontSize={20}
              _hover={{ bgColor: mainColor }}
              type="submit"
            >
              {" "}
              Senden{" "}
            </Button>
            <Datasecruity />
          </Stack>
        )}
      </Stack>
    );
  };

  const Ratings = (props) => {
    const { ...rest } = props;
    return (
      <>
        {variant !== "lg" && variant !== "md" ? (
          <Stack {...rest}>
            <Text fontSize={30} fontWeight={"extrabold"}>
              Ihre Bewertung
            </Text>
            <RadioGroup defaultValue={"5"}>
              <Stack spacing={2}>
                {stars.map((star, index) => {
                  return (
                    <StarRating
                      register={register}
                      key={index}
                      rating={star.rating}
                      name={star.name}
                    />
                  );
                })}
              </Stack>
            </RadioGroup>
          </Stack>
        ) : (
          <Stack h={elementHeight} {...rest}>
            <Text fontSize={30} mt={-3} fontWeight={"extrabold"}>
              Ihre Bewertung
            </Text>
            <Spacer />
            <RadioGroup defaultValue={"5"}>
              <Stack spacing={2}>
                {stars.map((star, index) => {
                  return (
                    <StarRating
                      register={register}
                      key={index}
                      rating={star.rating}
                      name={star.name}
                    />
                  );
                })}
              </Stack>
            </RadioGroup>
          </Stack>
        )}
      </>
    );
  };

  const elementHeight = 230;

  return (
    <Box>
      {window.location.pathname === "/bewertung" && <Header />}
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
        {variant === "lg" ? (
          <HStack
            alignItems={"flex-start"}
            h={elementHeight}
            spacing={10}
            {...rest}
          >
            <Ratings />
            <Spacer />
            <Textarea
              focusBorderColor={"#cc0201"}
              borderRadius={0}
              {...register("review", {
                required: true,
              })}
              placeholder="Ihr Bewertungstext?"
              h={elementHeight}
              w={"50vw"}
            />
            <Spacer />
            <Inputs spacer={true} />
          </HStack>
        ) : (
          <Center {...rest}>
            <Stack px={[2.5, 1, 1, 12]}>
              <Ratings w={["100%", "90vw", "80vw", "80vw"]} />
              <Stack spacing={[3, 5, 5, 5]} pt={5}>
                <Textarea
                  focusBorderColor={"#cc0201"}
                  borderRadius={0}
                  {...register("review", {
                    required: true,
                  })}
                  placeholder="Ihr Bewertungstext *"
                  w={["100%", "90vw", "80vw", "80vw"]}
                />
                <Inputs
                  w={["100%", "90vw", "80vw", "80vw"]}
                  spacers={false}
                  spacing={[3, 5, 5, 5]}
                  buttonPadding={5}
                />
              </Stack>
            </Stack>
          </Center>
        )}
      </form>
    </Box>
  );
}
