import {
  Box,
  Button,
  Input,
  Text,
  Stack,
  HStack,
  Spinner,
  Checkbox,
  Center,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import { useState } from "react";
import { mainColor } from "./mainColor";
import { useToastHook } from "./useToastHook";
import axios from "axios";
import { useForm } from "react-hook-form";
import { convertToHtml } from "./convertToHtml";

export function ShowOffer(props) {
  const { anwsers } = props;
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [test, setTest] = useState(0);
  const [toast, newToast] = useToastHook();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const convertToJSON = (props) => {
    let jsonList = [];
    for (let i = 0; i < anwsers.length; i++) {
      jsonList.push(JSON.stringify(anwsers[i]));
    }
    return JSON.stringify(jsonList);
  };

  const onSubmit = async (data) => {
    const { name, email, phone } = data;
    setLoading(true);
    try {
      axios
        .post(
          `https://kroll-bauausfuehrungen.de/php/projectPlaner.php?message=${convertToHtml(
            convertToJSON(),
            name,
            phone,
            email
          )}&email=${email}&name=${name}&tel=${phone}`
        )
        .catch((err) => console.error(err));

      //&images=${filterImages(convertToJSON())}
      //&json=${convertToJSON()}

      reset();
      convertToHtml(convertToJSON());
      setSent(true);
      setLoading(false);
      newToast({
        title: "Danke für Ihre Anfrage",
        message:
          "Ihre Anfrage wurde versendet, wir melden uns so schnell wie möglich bei Ihnen",
        status: "success",
      });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
    setLoading(false);
  };

  setTimeout(() => {
    setTest(test + 5);
  }, 100);

  return (
    <Box>
      {test > 100 ? (
        <>
          {loading ? (
            <Spinner size="xl" color={mainColor} />
          ) : (
            <>
              {sent === false ? (
                <Stack>
                  <Text fontWeight="bold" fontSize={28}>
                    {" "}
                    Wohin sollen wir Ihnen das Angebot schicken?{" "}
                  </Text>
                  <form
                    id="contact-form"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                  >
                    <Center>
                      <Stack w={"80%"}>
                        <Input
                          placeholder="Name*"
                          _placeholder={{ color: "black" }}
                          {...register("name", {
                            required: {
                              value: true,
                              message: "Bitte geben Sie ihren Namen ein",
                            },
                            maxLength: {
                              value: 30,
                              message:
                                "Bitte geben Sie 30 Zeichen, oder weniger ein",
                            },
                          })}
                        />
                        {errors.email && (
                          <Text color="red" fontSize="15">
                            Bitte geben Sie ihren Namen ein
                          </Text>
                        )}
                        <Input
                          placeholder="Emailadresse*"
                          _placeholder={{ color: "black" }}
                          {...register("email", {
                            required: true,
                            pattern:
                              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          })}
                        />
                        {errors.email && (
                          <Text color="red" fontSize="15">
                            Bitte geben Sie eine gültige Emailadresse ein
                          </Text>
                        )}
                        <Input
                          placeholder="Telefonnummer"
                          {...register("phone")}
                          _placeholder={{ color: "black" }}
                        />
                      </Stack>
                    </Center>

                    <Center>
                      <Stack>
                        <Center>
                          <HStack w={"60%"} pt={3}>
                            <Checkbox
                              size={"lg"}
                              colorScheme="red"
                              {...register("datasecruity", { required: true })}
                            />
                            <Text fontSize={12} textAlign="left">
                              Ich habe die
                              <a href="/datenschutz">
                                {" "}
                                Datenschutzerklärung
                              </a>{" "}
                              gelesen und stimme dieser zu.
                            </Text>
                          </HStack>
                        </Center>
                        {errors.datasecruity && (
                          <Text
                            color={mainColor}
                            textAlign={"center"}
                            fontSize={10}
                          >
                            {" "}
                            Bitte stimmen Sie unseren Nutzungsbedingungen und
                            der Datenschutzerklärung zu
                          </Text>
                        )}
                      </Stack>
                    </Center>

                    <Center pt={5}>
                      <Button
                        w={"40%"}
                        bgColor={mainColor}
                        _hover={{ bgColor: mainColor }}
                        type="submit"
                        color="white"
                      >
                        {" "}
                        Angebot erhalten{" "}
                      </Button>
                    </Center>
                  </form>
                </Stack>
              ) : (
                <Text fontWeight={"bold"} fontSize={24}>
                  {" "}
                  Danke für ihre Anfrage, wir melden uns so schnell wie möglich
                  bei ihnen{" "}
                </Text>
              )}
            </>
          )}
        </>
      ) : (
        <CircularProgress
          value={test}
          color={mainColor}
          size={"75px"}
          thickness="4px"
        >
          <CircularProgressLabel>{test} %</CircularProgressLabel>
        </CircularProgress>
      )}
    </Box>
  );
}
