import { useBreakpointValue } from "@chakra-ui/react";
import { AiOutlineMail } from "react-icons/ai";
import { Icon } from "./Icon";

export function EmailShowcase() {
  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });
  return (
    <Icon
      text={"info@kroll-bau.com"}
      link={() => (window.location = "mailto:info@kroll-bau.com")}
      icon={<AiOutlineMail size={variant === "lg" ? 60 : 45} />}
    />
  );
}
