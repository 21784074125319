import { Modal, ModalOverlay, ModalContent, Image } from "@chakra-ui/react";
import image from "./images/currently_in_progress.jpg";
import close_icon from "./images/icons/close.png";

export function CurrentlyInProgress({ isOpen, onOpen, onClose }) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Image src={image} />
          <Image
            src={close_icon}
            cursor="pointer"
            w={"16px"}
            position={"absolute"}
            top={5}
            right={5}
            onClick={() => onClose()}
          />
        </ModalContent>
      </Modal>
    </>
  );
}
