import {
  Box,
  Text,
  Center,
  HStack,
  useBreakpointValue,
  Wrap,
  WrapItem,
  Stack,
} from "@chakra-ui/react";
import { categories } from "./BlogContent";
import { calcVW } from "../calcVW";
import { FiFacebook } from "react-icons/fi";
import { FaWhatsapp, FaTelegramPlane, FaTwitter } from "react-icons/fa";

export function BlogSideBar(props) {
  const { setFilter, iconSize = 24, iconBoxSize = 45, ...rest } = props;
  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  const linkText = "Hier, guck was ich gefunden habe.";

  return (
    <>
      {variant !== "base" && variant !== "sm" ? (
        <Stack>
          <Center w={"100%"}>
            <Stack w={"23vw"} maxW={250} {...rest}>
              <Box borderColor="black" borderWidth={3} borderRadius={"lg"}>
                <Box mx={variant === "lg" ? 5 : 3}>
                  <Text
                    fontWeight="extrabold"
                    fontSize={calcVW(2.2, 22, 18)}
                    textAlign="center"
                    pt={3}
                  >
                    {" "}
                    WEITERSAGEN{" "}
                  </Text>
                  <br />
                  <Text textAlign="center" fontSize={15} color={"gray.500"}>
                    {" "}
                    Teile die Inahlte mit deinen Freunden
                  </Text>
                  <br />
                  <Center pb={10}>
                    <Wrap justify={"center"}>
                      <WrapItem>
                        <Box
                          borderRadius={"50%"}
                          borderColor={"black"}
                          borderWidth={1}
                          h={iconBoxSize}
                          w={iconBoxSize}
                          cursor="pointer"
                          onClick={() =>
                            (window.location = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`)
                          }
                        >
                          <Center h={"100%"} w={"100%"}>
                            <FiFacebook fontSize={iconSize} />
                          </Center>
                        </Box>
                      </WrapItem>
                      <WrapItem>
                        <Box
                          borderRadius={"50%"}
                          borderColor={"black"}
                          borderWidth={1}
                          h={iconBoxSize}
                          w={iconBoxSize}
                          cursor="pointer"
                          onClick={() =>
                            (window.location = `https://api.whatsapp.com/send?text=${linkText}%0a${window.location.href}`)
                          }
                        >
                          <Center h={"100%"} w={"100%"}>
                            <FaWhatsapp fontSize={iconSize} />
                          </Center>
                        </Box>
                      </WrapItem>
                      <WrapItem>
                        <Box
                          borderRadius={"50%"}
                          borderColor={"black"}
                          borderWidth={1}
                          h={iconBoxSize}
                          w={iconBoxSize}
                          cursor="pointer"
                          onClick={() =>
                            (window.location = `https://t.me/share/url?url=${window.location.href}&text=${linkText}`)
                          }
                        >
                          <Center h={"100%"} w={"100%"}>
                            <FaTelegramPlane fontSize={iconSize} />
                          </Center>
                        </Box>
                      </WrapItem>
                      <WrapItem>
                        <Box
                          borderRadius={"50%"}
                          borderColor={"black"}
                          borderWidth={1}
                          h={iconBoxSize}
                          w={iconBoxSize}
                          cursor="pointer"
                          onClick={() =>
                            (window.location = `https://twitter.com/intent/tweet?text=${window.location.href}`)
                          }
                        >
                          <Center h={"100%"} w={"100%"}>
                            <FaTwitter fontSize={iconSize} />
                          </Center>
                        </Box>
                      </WrapItem>
                    </Wrap>
                  </Center>
                </Box>
              </Box>
              <Box borderColor="black" borderWidth={3} borderRadius={"lg"}>
                <Box mx={5}>
                  <Text
                    fontWeight="extrabold"
                    fontSize={calcVW(2.2, 22, 18)}
                    textAlign="center"
                    pt={3}
                  >
                    {" "}
                    KATEGORIEN{" "}
                  </Text>
                  <br />
                  <Text
                    textAlign="center"
                    fontSize={[13, 13, 15, 15]}
                    color={"gray.500"}
                  >
                    {" "}
                    Entdecke weitere Themenspezifische Fachbeiträge, Infos und
                    wissenswerte Fakten rund die Bauausführung.
                  </Text>
                  <br />
                  <Center pb={10}>
                    <Stack>
                      <Text
                        textAlign="center"
                        cursor={"pointer"}
                        onClick={() => setFilter("")}
                        fontSize={18}
                        as="u"
                      >
                        {"Alle"}
                      </Text>
                      {categories().map((categorie, index) => {
                        return (
                          <Text
                            textAlign="center"
                            cursor={"pointer"}
                            key={index}
                            onClick={() => setFilter(categorie)}
                            fontSize={18}
                            as="u"
                          >
                            {" "}
                            {categorie}{" "}
                          </Text>
                        );
                      })}
                    </Stack>
                  </Center>
                </Box>
              </Box>
            </Stack>
          </Center>
        </Stack>
      ) : (
        <Center w={"100vw"} pt={10}>
          <Stack alignItems={"center"}>
            <Box
              borderColor="black"
              borderWidth={3}
              borderRadius={"lg"}
              w={"70vw"}
              minW={350}
            >
              <Box mx={[1, 1, 1, 3]}>
                <Text
                  fontWeight="extrabold"
                  fontSize={25}
                  textAlign="center"
                  pt={3}
                >
                  {" "}
                  KATEGORIEN{" "}
                </Text>
                <br />
                <Text
                  textAlign="center"
                  fontSize={[20, 20, 25, 25]}
                  color={"gray.500"}
                >
                  {" "}
                  Entdecke weitere Themenspezifische Fachbeiträge, Infos und
                  wissenswerte Fakten rund um die Bauausführung.
                </Text>
                <br />
                <Center pb={10}>
                  <Stack>
                    <Text
                      textAlign="center"
                      cursor={"pointer"}
                      onClick={() => setFilter("")}
                      fontSize={18}
                      as="u"
                    >
                      {"Alle"}
                    </Text>
                    {categories().map((categorie, index) => {
                      return (
                        <Text
                          key={index}
                          textAlign="center"
                          onClick={() => setFilter(categorie)}
                          fontSize={18}
                          as="u"
                        >
                          {" "}
                          {categorie}{" "}
                        </Text>
                      );
                    })}
                  </Stack>
                </Center>
              </Box>
            </Box>
            <Box
              borderColor="black"
              borderWidth={3}
              borderRadius={"lg"}
              w={350}
            >
              <Box mx={variant === "lg" ? 10 : 3}>
                <Text
                  fontWeight="extrabold"
                  fontSize={25}
                  textAlign="center"
                  pt={3}
                >
                  {" "}
                  WEITERSAGEN{" "}
                </Text>
                <br />
                <Text textAlign="center" fontSize={20} color={"gray.500"}>
                  {" "}
                  Teile die Inahlte mit deinen Freunden
                </Text>
                <br />
                <Center pb={10}>
                  <HStack>
                    {/*                     <Box
                      borderRadius={'50%'}
                      borderColor={'black'}
                      borderWidth={1}
                      h={iconBoxSize}
                      w={iconBoxSize}
                    >
                      <Center h={'100%'} w={'100%'}>
                        <BsFacebook fontSize={iconSize} />
                      </Center>
                    </Box> */}
                    {/*                     <Box
                      borderRadius={"50%"}
                      borderColor={"black"}
                      borderWidth={1}
                      h={iconBoxSize}
                      w={iconBoxSize}
                    >
                      <Center h={"100%"} w={"100%"}>
                        <BsWhatsapp fontSize={iconSize} />
                      </Center>
                    </Box> */}
                    {/*                     <Box
                      borderRadius={"50%"}
                      borderColor={"black"}
                      borderWidth={1}
                      h={iconBoxSize}
                      w={iconBoxSize}
                    >
                      <Center h={"100%"} w={"100%"}>
                        <BsTelegram fontSize={iconSize} />
                      </Center>
                    </Box> */}
                    {/*                     <Box
                      borderRadius={'50%'}
                      borderColor={'black'}
                      borderWidth={1}
                      h={iconBoxSize}
                      w={iconBoxSize}
                    >
                      <Center h={'100%'} w={'100%'}>
                        <BsTwitter fontSize={iconSize} />
                      </Center>
                    </Box> */}
                  </HStack>
                </Center>
              </Box>
            </Box>
          </Stack>
        </Center>
      )}
    </>
  );
}

export default BlogSideBar;
