import { Box, useBreakpointValue } from "@chakra-ui/react";
import "./DifferentScreenSizeTemplates/RenderBlogArticle.css";

import { TabletBlogArticle } from "./DifferentScreenSizeTemplates/TabletBlogArticle";
import { DesktopBlogArticle } from "./DifferentScreenSizeTemplates/DesktopBlogArticle";
import { MobileBlogArticle } from "./DifferentScreenSizeTemplates/MobileBlogArticle";
import { Header } from "../Header";

export function BlogArticle(props) {
  const { children, article } = props;

  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  return (
    <Box>
      <Header />
      {variant === "lg" ? (
        <DesktopBlogArticle article={article}>
          <Box>{children}</Box>
        </DesktopBlogArticle>
      ) : variant === "md" ? (
        <TabletBlogArticle article={article}>
          <Box>{children}</Box>
        </TabletBlogArticle>
      ) : (
        <MobileBlogArticle article={article}>
          <Box>{children}</Box>
        </MobileBlogArticle>
      )}
    </Box>
  );
}
