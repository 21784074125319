import { Header } from "../components/Header";
import {
  Image,
  Tooltip,
  Stack,
  Box,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

// Bilder
import bad from "../components/images/tileWork/bad.webp";
import küche from "../components/images/tileWork/küche.jpeg";
import mosaik from "../components/images/tileWork/mosaik.jpeg";
import silikon from "../components/images/tileWork/silikon.jpeg";
import wohnräume from "../components/images/tileWork/wohnräume.jpeg";
import decke from "../components/images/concrete/decke.jpeg";
import fundament from "../components/images/concrete/fundament.jpeg";
import sanierung from "../components/images/concrete/sanierung.jpeg";
import sichtbeton from "../components/images/concrete/sichtbeton.jpeg";
import treppe from "../components/images/concrete/treppe.jpeg";
import carport from "../components/images/bricklaying/carport.jpeg";
import fassadensanierung from "../components/images/bricklaying/fassadensanierung.jpeg";
import fensterbau from "../components/images/bricklaying/fensterbau.webp";
import treppe2 from "../components/images/bricklaying/treppe.jpeg";
import verklinkern from "../components/images/bricklaying/verklinkern.webp";
import wanddurchbruch from "../components/images/bricklaying/wanddurchbruch.jpeg";
import { mainColor } from "../components/mainColor";
import { useEffect } from "react";

export function Reference(props) {
  const { title, images } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const pictures = [
    bad,
    küche,
    mosaik,
    silikon,
    wohnräume,
    decke,
    fundament,
    sanierung,
    sichtbeton,
    treppe,
    carport,
    fassadensanierung,
    fensterbau,
    treppe2,
    verklinkern,
    verklinkern,
    wanddurchbruch,
  ];

  const Tip = (props) => {
    const { location, date } = props;
    return (
      <Box
        minW={200}
        color="white"
        textAlign={"center"}
        fontSize={17}
        fontWeight="bold"
      >
        <Text>
          {" "}
          {location}, {date}
        </Text>
      </Box>
    );
  };

  return (
    <>
      <Header />
      <Stack pt={5}>
        <Text fontSize={30} fontWeight={"extrabold"} textAlign={"center"}>
          {" "}
          {title}
        </Text>
        <Wrap justify={"center"}>
          {images?.map((pic, i) => {
            return (
              <WrapItem key={i}>
                <Tooltip
                  label={<Tip location={"Laboe"} date={"27.10.22"} />}
                  bg={mainColor}
                  borderRadius={5}
                  aria-label="Popup das angibt, wann und wo das Bild gemacht wurde"
                >
                  <Image src={pic} w={"15vw"} minW={300} />
                </Tooltip>
              </WrapItem>
            );
          })}
        </Wrap>
      </Stack>
    </>
  );
}
