import {
  Stack,
  Text,
  Button,
  Input,
  Center,
  HStack,
  Box,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { mainColor } from "./mainColor";
import axios from "axios";
import { useToastHook } from "./useToastHook";
import "./main.css";
import { useState } from "react";

export function ApplicationTool({ ...rest }) {
  const [, newToast] = useToastHook();
  const [workExperienceState, setWorkExperience] = useState("Keine Erfahrung");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const {
      name,
      birthdate,
      adress,
      email,
      tel,
      message,
      ausbildung,
      driverLicence,
      workHistory,
    } = data;
    try {
      let ausbildungListe = "";
      Object.keys(ausbildung).forEach(function (key, index) {
        if (ausbildungListe[key] !== "false") {
          ausbildungListe += `${ausbildung[key]} `;
        }
      });
      ausbildungListe = ausbildungListe.replace("false", "");
      const response = await axios.post(
        `https://kroll-bau.com/php/applicationTool.php?name=${name}&email=${email}&message=${message}&tel=${tel}&birthdate=${birthdate}&adress=${adress}&ausbildung=${ausbildungListe}&driverLicence=${driverLicence}&workExperience=${workExperienceState}&workHistory=${workHistory}`
      );
      console.log(response.data);
      if (response.data === "Message sent successfully...") {
        reset();
        newToast({
          title: "Danke für ihre Anfrage",
          message:
            "Ihre Anfrage wurde versendet, wir melden uns so schnell wie möglich bei Ihnen",
          status: "success",
        });
      } else {
        newToast({
          title: "Ein Fehler ist aufgetreten",
          message:
            "Ihre Anfrage konnte nicht gesendet werden. Bitte versuchen Sie es erneut oder kontaktieren Sie uns telefonisch.",
          status: "error",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const Datasecruity = (props) => {
    return (
      <Center>
        <Stack>
          <Center>
            <HStack w={"100%"} pt={3}>
              <Checkbox
                size={"lg"}
                colorScheme="red"
                {...register("datasecruity", { required: true })}
              />
              <Text fontSize={12} textAlign="left">
                Ich habe die
                <a href="/datenschutz"> Datenschutzerklärung</a> gelesen und
                stimme dieser zu.
              </Text>
            </HStack>
          </Center>
          {errors.datasecruity && (
            <Text color={mainColor} textAlign={"center"} fontSize={10}>
              {" "}
              Bitte stimmen Sie unseren Nutzungsbedingungen und der
              Datenschutzerklärung zu
            </Text>
          )}
        </Stack>
      </Center>
    );
  };

  return (
    <Stack fontWeight={"bold"} textAlign={"center"} {...rest}>
      <Text fontSize={26}> Kurzbewerbungsformular</Text>
      <Text>
        Mit diesem Kurz-Bewerbungstool kannst du dich im Handumdrehen bei uns{" "}
        <br /> vorstellen. Selbstverständlich kannst du aber auch direkt bei uns
        anrufen und wir <br /> klären die ersten Eckpunkte im kurzen Gespräch.
      </Text>
      <Text pt={5}>
        {" "}
        Ansprechpartner: Thorben Kroll <br />
        Telefon: 04343 4390470 <br />
        oder per E-Mail: jobs@kroll-bau.com
      </Text>
      <Text pt={5}>Wir freuen uns schon darauf, dich kennenzulernen!</Text>
      <Center py={5}>
        <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack w={600} maxW={"90vw"} textAlign={"left"}>
            <Stack spacing={1}>
              <Input
                _focus={{
                  borderWidth: "0px",
                  borderBottomWidth: "2px",
                  borderBottomColor: mainColor,
                  boxShadow: `0px 1px 0px 0px ${mainColor}`,
                }}
                {...register("name", { required: true })}
                variant="flushed"
                placeholder="Vor- und Nachname *"
              />
              {errors.name && (
                <Text color={mainColor}> Bitte gebe deinen Namen ein. </Text>
              )}
              <Input
                _focus={{
                  borderWidth: "0px",
                  borderBottomWidth: "2px",
                  borderBottomColor: mainColor,
                  boxShadow: `0px 1px 0px 0px ${mainColor}`,
                }}
                {...register("birthdate", { required: true })}
                variant="flushed"
                placeholder="Geburtstag *"
                className="unstyled"
              />
              {errors.name && (
                <Text color={mainColor}>
                  {" "}
                  Bitte gebe deinen Geburtstag ein.{" "}
                </Text>
              )}
              <Input
                _focus={{
                  borderWidth: "0px",
                  borderBottomWidth: "2px",
                  borderBottomColor: mainColor,
                  boxShadow: `0px 1px 0px 0px ${mainColor}`,
                }}
                {...register("adress", { required: true })}
                variant="flushed"
                placeholder="Adresse *"
              />
              {errors.name && (
                <Text color={mainColor}> Bitte gebe deinen Adresse ein. </Text>
              )}
              <Input
                _focus={{
                  borderWidth: "0px",
                  borderBottomWidth: "2px",
                  borderBottomColor: mainColor,
                  boxShadow: `0px 1px 0px 0px ${mainColor}`,
                }}
                {...register("email", { required: true })}
                variant="flushed"
                placeholder="E-Mail *"
              />
              {errors.name && (
                <Text color={mainColor}> Bitte gebe deine E-Mail ein. </Text>
              )}
              <Input
                _focus={{
                  borderWidth: "0px",
                  borderBottomWidth: "2px",
                  borderBottomColor: mainColor,
                  boxShadow: `0px 1px 0px 0px ${mainColor}`,
                }}
                {...register("tel", { required: true })}
                variant="flushed"
                type={"tel"}
                placeholder="Telefon *"
              />
              {errors.name && (
                <Text color={mainColor}>
                  {" "}
                  Bitte gebe deine Telefonnummer ein.{" "}
                </Text>
              )}
            </Stack>
            <Text pt={5}>Hast du bereits eine Ausbildung abgeschlossen?</Text>
            <Stack spacing={1}>
              <CheckboxGroup colorScheme="red">
                <Checkbox
                  colorScheme="red"
                  value={"Maurer"}
                  {...register("ausbildung.maurer")}
                >
                  Maurer
                </Checkbox>
                <Checkbox
                  colorScheme="red"
                  value={"Fliesenleger"}
                  {...register("ausbildung.fliesenleger")}
                >
                  Fliesenleger
                </Checkbox>
                <Checkbox
                  colorScheme="red"
                  value={"Hochbaufacharbeiter"}
                  {...register("ausbildung.hochbaufacharbeiter")}
                >
                  Hochbaufacharbeiter
                </Checkbox>
                <Checkbox
                  colorScheme="red"
                  value={"andere"}
                  {...register("ausbildung.andere")}
                >
                  andere
                </Checkbox>
                <Checkbox
                  colorScheme="red"
                  value={"keine"}
                  {...register("ausbildung.keine")}
                >
                  keine
                </Checkbox>
              </CheckboxGroup>
            </Stack>
            <Text pt={5}>
              Wo hast du in den letzten 5 Jahren gearbeitet, wie lange und was
              hast du da gemacht?
            </Text>
            <Input
              _focus={{
                borderWidth: "0px",
                borderBottomWidth: "2px",
                borderBottomColor: mainColor,
                boxShadow: `0px 1px 0px 0px ${mainColor}`,
              }}
              variant="flushed"
              placeholder={"Antwort hier eingeben *"}
              {...register("workHistory")}
            />
            {errors.name && (
              <Text color={mainColor}>
                {" "}
                Bitte teil uns mit was du die letzten 5 Jahre gemacht hast.{" "}
              </Text>
            )}
            <Text pt={5}>
              Erfahrung, arbeitest du bereits dein ganzes Leben auf der
              Baustelle oder steigst du gerade in dieses Berufsfeld ein?
            </Text>
            {/*                         <Select variant='flushed' placeholder='Wähle aus' {...register("workExperience")} className={"decorated"}>
                            <option value='Keine Erfahrung'>Keine Erfahrung</option>
                            <option value='Wenig Erfahrung'>Wenig Erfahrung</option>
                            <option value='Viel Erfahrung'>Viel Erfahrung</option>
                            <option value='Sehr viel Erfahrung'>Sehr viel Erfahrung</option>
                            <option value='Experte'>Experte</option>
                        </Select> */}
            <Stack>
              <Box
                onClick={() => {
                  setWorkExperience("Keine Erfahrung");
                }}
              >
                {" "}
                <Checkbox
                  colorScheme="red"
                  isChecked={workExperienceState === "Keine Erfahrung"}
                >
                  Keine Erfahrung
                </Checkbox>
              </Box>
              <Box
                onClick={() => {
                  setWorkExperience("Wenig Erfahrung");
                }}
              >
                {" "}
                <Checkbox
                  colorScheme="red"
                  isChecked={workExperienceState === "Wenig Erfahrung"}
                >
                  Wenig Erfahrung
                </Checkbox>
              </Box>

              <Box
                onClick={() => {
                  setWorkExperience("Viel Erfahrung");
                }}
              >
                {" "}
                <Checkbox
                  colorScheme="red"
                  isChecked={workExperienceState === "Viel Erfahrung"}
                >
                  Viel Erfahrung
                </Checkbox>
              </Box>
              <Box
                onClick={() => {
                  setWorkExperience("Sehr viel Erfahrung");
                }}
              >
                {" "}
                <Checkbox
                  colorScheme="red"
                  isChecked={workExperienceState === "Sehr viel Erfahrung"}
                >
                  Sehr viel Erfahrung
                </Checkbox>
              </Box>
              <Box
                onClick={() => {
                  setWorkExperience("Experte");
                }}
              >
                {" "}
                <Checkbox
                  colorScheme="red"
                  isChecked={workExperienceState === "Experte"}
                >
                  Experte
                </Checkbox>
              </Box>
            </Stack>
            <Text pt={5}>Hast du einen Führerschein? Wenn ja, welchen?</Text>
            <Input
              _focus={{
                borderWidth: "0px",
                borderBottomWidth: "2px",
                borderBottomColor: mainColor,
                boxShadow: `0px 1px 0px 0px ${mainColor}`,
              }}
              variant="flushed"
              placeholder={"Antwort hier eingeben *"}
              {...register("driverLicence", { required: true })}
            />
            {errors.name && (
              <Text color={mainColor}>
                {" "}
                Bitte gib an, ob und welche Führerscheinklassen du besitzt.{" "}
              </Text>
            )}
            <Text pt={5}>
              Hier kannst du noch eine Nachricht hinterlassen, was du sonst noch
              erzählen möchtest.
            </Text>
            <Input
              _focus={{
                borderWidth: "0px",
                borderBottomWidth: "2px",
                borderBottomColor: mainColor,
                boxShadow: `0px 1px 0px 0px ${mainColor}`,
              }}
              variant="flushed"
              placeholder={"Antwort hier eingeben *"}
              {...register("message")}
            />
            <Box pt={7} w={"100%"}>
              <Button
                w={"100%"}
                textAlign={"center"}
                fontWeight={"extrabold"}
                bgColor={mainColor}
                _hover={{ bgColor: mainColor }}
                color="white"
                type="submit"
                borderRadius={0}
              >
                SENDEN
              </Button>
            </Box>
            <Datasecruity />
          </Stack>
        </form>
      </Center>
    </Stack>
  );
}
