import {
  Box,
  Center,
  Stack,
  HStack,
  Text,
  Image,
  Spacer,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ViewIcon, ChatIcon, TimeIcon } from "@chakra-ui/icons";
import "./RenderBlogArticle.css";

export function MobileBlogArticle(props) {
  const { children, setProduct, previewItems, article } = props;

  const variant = useBreakpointValue({ base: 2, sm: 3 });

  return (
    <Box>
      <Stack spacing={0} pt={10}>
        <Center>
          <Stack w={"95vw"}>
            <Image
              src={article.pictureLink}
              alt={article.pictureAlt}
              borderRadius={10}
            />
            <Text fontSize={28} as="u" textAlign="center" fontWeight="bold">
              {" "}
              {article.header}{" "}
            </Text>
            <Center>
              <HStack spacing={7} fontSize={18} pb={5}>
                <HStack>
                  <TimeIcon />
                  <Text color="gray.500">20.04.2022</Text>
                </HStack>
                <HStack>
                  <ChatIcon />
                  <Text color="gray.500">{article.comments.length} </Text>
                </HStack>
                <HStack>
                  <ViewIcon />
                  <Text color="gray.500">{article.viewCount}</Text>
                </HStack>
              </HStack>
            </Center>
            <Box>{children}</Box>
          </Stack>
        </Center>
        {/*         <BlogSideBar iconSize={30} iconBoxSize={57} /> */}
        <Spacer />
      </Stack>
    </Box>
  );
}
